import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { Text } from 'components';
import styled from 'styled-components';

type Props = {
    isAvailable: boolean;
    isLoaded: boolean;
    entitiesLength?: number;
    numberOfVisibleRows: number;
};

export default function RowsInfo({ isAvailable, isLoaded, entitiesLength, numberOfVisibleRows }: Props) {
    const numberOfTotalRows = isLoaded && entitiesLength ? entitiesLength : '0';

    if (!isAvailable) {
        return null;
    }

    return (
        <Wrapper>
            <Text variant='body-small'>Showing </Text>
            <Text variant='body-small-bold'>{numberOfVisibleRows} </Text>
            <Text variant='body-small'>of </Text>
            <Text variant='body-small-bold'>{numberOfTotalRows}</Text>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding-top: ${SPACING.XS}px;
    text-align: right;
`;
