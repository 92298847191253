import { LegalEntityDTO, ReferenceData, ReferencedDataTypeId } from '../../models';
import { LegalEntityRequest, LogError } from '../../models';
import {
    ReferenceData as NewReferenceData,
    ReferenceDataId as NewReferenceDataId,
} from '../../api/models/reference-data';

import { CanDissolveEntityResponse } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionTreasury/model';
import { LegalEntityDissolutionData } from 'models/LegalEntityRequest/LegalEntityDissolution';
import { RecursivePartial } from '../../Utilities/ReflectionUtil';
import { WorkItemState } from 'modules/RequestsDashboard/models';
import { entitySections } from '../../modules/LegalEntityCreation/LegalEntityCreationView/EntityToSectionMapper';
import { LegalEntityStatus } from 'models/LegalEntityRequest/LegalEntityStatus';

export abstract class ILegalEntityService {
    abstract logInfo: LogError;

    public abstract getReferenceData(ids: NewReferenceDataId[]): Promise<NewReferenceData>;
    public abstract GetAllRefData(getRefIds: ReferencedDataTypeId[]): Promise<ReferenceData>;
    public abstract GetUserBusinessUnits(): Promise<ReferenceData>;
    public abstract GetUserSubBusinessUnits(): Promise<ReferenceData>;
    public abstract GetLegalEntities(
        useCache: boolean,
        workItemState: WorkItemState,
        fromDate?: string,
        toDate?: string
    ): Promise<LegalEntityRequest[]>;
    public abstract GetLegalEntity(entityOId: number, includePendingChanges?: boolean): Promise<LegalEntityDTO>;
    public abstract CreateLegalEntity(legalEntityDTO: RecursivePartial<LegalEntityDTO>): Promise<LegalEntityDTO>;
    public abstract UpdateLegalEntity(
        entityOId: number,
        section: keyof entitySections,
        legalEntityDTO: RecursivePartial<LegalEntityDTO>
    ): Promise<LegalEntityDTO>;
    public abstract GetLegalEntityDissolutionData(workflowId: number): Promise<LegalEntityDissolutionData>;
    public abstract SaveLegalEntityDissolutionData(
        dissolutionData: RecursivePartial<LegalEntityDissolutionData>
    ): Promise<LegalEntityDissolutionData>;
    public abstract UpdateLegalEntityDissolutionData(
        workflowId: number,
        dissolutionData: RecursivePartial<LegalEntityDissolutionData>,
        section: keyof entitySections
    ): Promise<LegalEntityDissolutionData>;
    public abstract GetCanDissolveEntity(entityOId: number): Promise<CanDissolveEntityResponse>;
    public abstract GetEntiyStatuses(entityOIds: number[]): Promise<LegalEntityStatus[]>;
}
