import { NewStructureType } from './NewStructureType';

export function USRegistrationsAndREIT(hasUsRegistrations?: boolean, newStructureTypeId?: number) {
    return hasUsRegistrations && newStructureTypeId === NewStructureType.REIT;
}

export function NoUSRegistrationsOrExempt(
    hasUsRegistrations?: boolean,
    corporateTransparencyStatusInternal?: number,
    exemptKey?: number
) {
    return !hasUsRegistrations || corporateTransparencyStatusInternal === exemptKey;
}
