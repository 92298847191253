import { AuthorizedPersonModel, LegalEntityOwnershipInformationData, OwnershipDetailsModel } from '../model';
import { isEqual, isNil } from 'lodash';
import { useCallback, useMemo } from 'react';

type Props = {
    data: LegalEntityOwnershipInformationData;
    noPendingData?: LegalEntityOwnershipInformationData;
};

export default function useOwnershipWithIsPending({ data, noPendingData }: Props) {
    const isPendingChange = useCallback(
        (newValue?: any, noPendingValue?: any) => {
            return !isNil(noPendingData) ? !isEqual(newValue, noPendingValue) : undefined;
        },
        [noPendingData]
    );

    const hasAuthorizedPersonsPendingChanges = useCallback(
        (authorizedPerson: AuthorizedPersonModel) => {
            const noPendingValues = noPendingData?.authorizedPerson;

            if (!noPendingValues) {
                return false;
            }

            const nonPendingData = noPendingValues.find(({ index }) => index === authorizedPerson.index);

            if (!nonPendingData) {
                return true; // new item
            }

            return (
                isPendingChange(authorizedPerson?.name, nonPendingData?.name) ||
                isPendingChange(authorizedPerson?.title, nonPendingData?.title) ||
                isPendingChange(authorizedPerson?.appointmentDate, nonPendingData?.appointmentDate) ||
                isPendingChange(authorizedPerson?.resignationDate, nonPendingData?.resignationDate)
            );
        },
        [noPendingData, isPendingChange]
    );

    const hasOwnershipDetailsPendingChanges = useCallback(
        (ownershipDetail: OwnershipDetailsModel) => {
            const noPendingValues = noPendingData?.ownershipDetails;

            if (!noPendingValues) {
                return false;
            }

            const nonPendingData = noPendingValues.find(({ index }) => index === ownershipDetail.index);

            if (!nonPendingData) {
                return true; // new item
            }

            return (
                isPendingChange(ownershipDetail?.parentEntity, nonPendingData?.parentEntity) ||
                isPendingChange(ownershipDetail?.interestUnit, nonPendingData?.interestUnit) ||
                isPendingChange(ownershipDetail?.interestClass, nonPendingData?.interestClass) ||
                isPendingChange(ownershipDetail?.numberOfUnits, nonPendingData?.numberOfUnits) ||
                isPendingChange(ownershipDetail?.ownerShip, nonPendingData?.ownerShip) ||
                isPendingChange(ownershipDetail?.reportingType, nonPendingData?.reportingType) ||
                isPendingChange(ownershipDetail?.interestType, nonPendingData?.interestType) ||
                isPendingChange(ownershipDetail?.shareClass, nonPendingData?.shareClass) ||
                isPendingChange(ownershipDetail?.currency, nonPendingData?.currency) ||
                isPendingChange(ownershipDetail?.votingRight, nonPendingData?.votingRight) ||
                isPendingChange(ownershipDetail?.nominalValue, nonPendingData?.nominalValue)
            );
        },
        [noPendingData, isPendingChange]
    );

    const ownerShipWithIsPending = useMemo(() => {
        const dataWithIsPending = { ...data };

        if (data.authorizedPerson) {
            dataWithIsPending.authorizedPerson = data.authorizedPerson.map((authPerson) => {
                const item = { ...authPerson };
                item.hasPendingChange = hasAuthorizedPersonsPendingChanges(item);

                return item;
            });
        }

        if (data.ownershipDetails) {
            dataWithIsPending.ownershipDetails = data.ownershipDetails.map((ownershipDetail) => {
                const item = { ...ownershipDetail };
                item.hasPendingChange = hasOwnershipDetailsPendingChanges(item);

                return item;
            });
        }

        return dataWithIsPending;
    }, [data, hasAuthorizedPersonsPendingChanges, hasOwnershipDetailsPendingChanges]);

    return ownerShipWithIsPending;
}
