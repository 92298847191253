import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

import { BRNS, LegalEntityRequest } from '../../models';
import { BooleanField, Text } from 'components';
import { CellClickedEvent, CellDoubleClickedEvent, ColDef, ModelUpdatedEvent } from 'ag-grid-community';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StackPanel, TextField } from '@bxgrandcentral/controls';
import { isEqual, isNil } from 'lodash';
import { updateDashboardFilters, useAppContext } from 'context/app-context';
import useColumnConfiguration, { GridApiRef } from 'hooks/use-column-configuration';

import { AgGridReact } from 'ag-grid-react';
import ColumnConfiguration from './components/ColumConfiguration/ColumnConfiguration';
import DateRangeInfo from './components/DateRangeInfo/DateRangeInfo';
import DateRangePicker from './components/DateRangePicker/DateRangePicker';
import Export from './components/Export/Export';
import GridLoadingOverlay from './components/Grid/components/GridLoadingOverlay';
import GridNoRowsOverlay from './components/Grid/components/GridNoRowsOverlay';
import { LegalEntityRefetch } from 'api/hooks/use-legal-entities';
import NewTabButton from 'modules/LegalEntityCreation/Shared/NewTabButton';
import RowsInfo from './components/RowsInfo/RowsInfo';
import { SPACING } from 'Utilities/Layout';
import { preProcessFields } from './utils';
import styled from 'styled-components';
import useContextMenu from 'hooks/use-context-menu';
import useGridStyleVars from './hooks/use-grid-style-vars';
import useNavigation from 'hooks/use-navigation';
import useUserInformation from 'hooks/use-user-information';

interface Props {
    data?: LegalEntityRequest[];
    onRefresh: LegalEntityRefetch;
    isLoading: boolean;
    isError: boolean;
}

export default function RequestsDashboardGrid({ data: entities, onRefresh, isLoading, isError }: Props) {
    const { brns } = useUserInformation();

    const {
        state: {
            dashboard: { collapsedEntities, searchCriteria, groupByParentEntities, dashboardView, isCtaReview },
        },
        dispatch,
    } = useAppContext();

    const defaultColumnDef = useMemo<ColDef>(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            flex: 1,
            sortingOrder: ['asc', 'desc'],
        }),
        []
    );

    const [filteredData, setFilteredData] = useState(entities);

    const gridRef = useRef<AgGridReact>(null);

    const { navigateToRequest, dashboardNewTabLink } = useNavigation();

    const { contextMenuPosition, handleCellContextMenu, closeContextMenu, ctrlKeyEvent } = useContextMenu({ gridRef });

    const apiRef = React.useRef({
        column: undefined,
        api: undefined,
    } as GridApiRef);

    const { columnDefs, setColumnDefs } = useColumnConfiguration(filteredData, apiRef?.current);

    const { panelHeight, theme } = useGridStyleVars();

    const [shouldReloadGrid, setShouldReloadGrid] = useState(false);

    const [numberOfRows, setNumberOfRows] = useState(entities?.length || 0);

    const isControlsEnabled = !isLoading && !shouldReloadGrid;

    const doGridRefresh = () => {
        setShouldReloadGrid(true);
        gridRef.current?.api.showLoadingOverlay();
        // temporary workaround until we figure out how can we pass arguments to react-query refetch fn directly
        setTimeout(() => {
            onRefresh();
        }, 0);
    };

    const onGridReady = (params: any) => {
        apiRef.current.column = params.columnApi;
        apiRef.current.api = params.api;
    };

    const applySearchCriteria = useCallback(
        (data: LegalEntityRequest[]) => {
            if (searchCriteria.length < 3) {
                return data;
            }
            return data.filter(
                ({ entityOId, primaryName }) =>
                    `${entityOId}`.includes(searchCriteria) || primaryName.toLowerCase().includes(searchCriteria)
            );
        },
        [searchCriteria]
    );

    const removeCollapsedEntities = useCallback(
        (data: LegalEntityRequest[]) => {
            const { length } = collapsedEntities;
            return length
                ? data.filter(
                      ({ workItemType, entityOId }) =>
                          !collapsedEntities.includes(entityOId) ||
                          workItemType === 'LegalEntityCreationWorkflow' ||
                          workItemType === 'DissolutionParentWorkflow'
                  )
                : data;
        },
        [collapsedEntities]
    );

    const getPreProcessedData = useCallback((data: LegalEntityRequest[]) => {
        return preProcessFields(data);
    }, []);

    const resizeColumns = () => {
        (apiRef.current?.api as any).sizeColumnsToFit();
    };

    const onModelUpdated = useCallback(
        ({ api }: ModelUpdatedEvent) => {
            const rowCount = api.getModel().getRowCount();
            const displayedRowCount = api.getDisplayedRowCount();

            if (numberOfRows !== rowCount) {
                setNumberOfRows(rowCount);
            }

            if (displayedRowCount === 0 && !isLoading) {
                gridRef.current?.api.showNoRowsOverlay();
            }
        },
        [numberOfRows, isLoading]
    );

    useEffect(() => {
        if (columnDefs && apiRef?.current && apiRef.current?.api) {
            apiRef?.current!.api?.setColumnDefs(columnDefs);
        }
    }, [columnDefs]);

    useEffect(() => {
        if (brns.includes(BRNS.CTA_APPROVER) && isCtaReview === undefined) {
            updateDashboardFilters(dispatch, {
                collapsedEntities,
                searchCriteria,
                groupByParentEntities,
                dashboardView,
                isCtaReview: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brns]);

    useEffect(() => {
        if (gridRef.current?.api) {
            doGridRefresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardView]);

    useEffect(() => {
        if (entities?.length) {
            const updatedFilteredData = removeCollapsedEntities(applySearchCriteria(getPreProcessedData(entities)));
            if (!isEqual(updatedFilteredData, filteredData)) {
                setFilteredData(updatedFilteredData);
                setShouldReloadGrid(false);
            }
        }
    }, [removeCollapsedEntities, applySearchCriteria, entities, filteredData, getPreProcessedData]);

    useEffect(() => {
        if (!entities?.length && !isLoading) {
            setFilteredData([]);
        }
    }, [entities, isLoading]);

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                gridRef.current?.api.showLoadingOverlay();
            }, 500);
        } else {
            gridRef.current?.api.hideOverlay();
        }
        if (isError) {
            gridRef.current?.api.showNoRowsOverlay();
        }
        if (!isLoading && !isError) {
            setShouldReloadGrid(false);
        }
    }, [isError, isLoading]);

    const noRowsMessage =
        searchCriteria || (apiRef.current?.api as any)?.isAnyFilterPresent()
            ? 'There are no matching entities.'
            : 'There are no entities pending your action.';

    return (
        <StackPanel margin={`${SPACING.XS}px ${SPACING.MD}px ${SPACING.SM}px ${SPACING.MD}px`} height={panelHeight}>
            <DashboardLayout className={theme}>
                <HeaderLayout>
                    <StackPanel
                        orientation='horizontal'
                        verticalAlignment='center'
                        itemGap={SPACING.SM}
                        {...(isLoading && { opacity: 0.5 })}
                        styleOverrides={{ justifyContent: 'space-between' }}>
                        <StackPanel orientation='horizontal' verticalAlignment='center' horizontalAlignment='left'>
                            <TextField
                                iconName='Search'
                                toolTip={'Search for entity on the dashboard'}
                                canUndo={false}
                                value={searchCriteria}
                                onValueChanged={(newValue = '') =>
                                    updateDashboardFilters(dispatch, {
                                        collapsedEntities,
                                        searchCriteria: newValue.toLowerCase(),
                                        groupByParentEntities,
                                        dashboardView,
                                        isCtaReview,
                                    })
                                }
                                isEnabled={isControlsEnabled}
                                prompt='Search Entity on Dashboard'
                                styleName='sideBarStyle'
                                styleOverrides={{
                                    flex: 1,
                                    marginBottom: `${SPACING.XXS}px`,
                                    marginRight: `${SPACING.SM}px`,
                                }}
                                canResetValue
                                width={600}
                                height={30}
                            />
                        </StackPanel>
                        <StackPanel orientation='horizontal' itemGap={SPACING.LG}>
                            <StackPanel orientation='horizontal'>
                                <DateRangePicker
                                    iconTooltipText={'Filter by last updated date range'}
                                    onRefresh={() => {
                                        doGridRefresh();
                                    }}
                                    styleOverrides={{ marginTop: '6px' }}
                                    isEnabled={!isLoading}
                                />
                                <DateRangeInfo />
                            </StackPanel>
                            <StackPanel orientation='horizontal' verticalAlignment='center'>
                                <Text variant='body-small-bold' styleOverrides={{ paddingTop: '6px' }}>
                                    CTA Review
                                </Text>
                                <BooleanField
                                    defaultValue={false}
                                    canResetValue={false}
                                    canUndo={false}
                                    trueValueLabel=''
                                    falseValueLabel=''
                                    value={isCtaReview}
                                    variant='dashboard'
                                    onValueChanged={(newValue = false) => {
                                        updateDashboardFilters(dispatch, {
                                            collapsedEntities,
                                            searchCriteria,
                                            groupByParentEntities,
                                            dashboardView,
                                            isCtaReview: newValue,
                                        });
                                    }}
                                    isEnabled={isControlsEnabled}
                                />
                            </StackPanel>
                            <Export
                                apiRef={apiRef?.current}
                                filteredData={filteredData}
                                isEnabled={isControlsEnabled}
                            />
                            <ColumnConfiguration
                                columnDefs={columnDefs}
                                setColumnDefs={setColumnDefs}
                                isEnabled={isControlsEnabled}
                            />
                        </StackPanel>
                    </StackPanel>
                </HeaderLayout>
                <GridLayout>
                    <AgGridReact
                        rowData={shouldReloadGrid ? undefined : filteredData}
                        ref={gridRef}
                        columnDefs={columnDefs}
                        onCellClicked={({ data, event }: CellClickedEvent<LegalEntityRequest>) => {
                            ctrlKeyEvent(event, data);
                        }}
                        onCellDoubleClicked={({ data }: CellDoubleClickedEvent) => navigateToRequest(data)}
                        onGridReady={onGridReady}
                        onFirstDataRendered={resizeColumns}
                        rowClass='custom-row'
                        loadingOverlayComponent={GridLoadingOverlay}
                        onSortChanged={({ columnApi }) => {
                            const { colId = '' } = columnApi.getColumnState().find(({ sort }) => !isNil(sort)) ?? {};
                            const isEntityClicked = colId === 'entityOId';
                            updateDashboardFilters(dispatch, {
                                collapsedEntities: isEntityClicked ? collapsedEntities : [],
                                searchCriteria,
                                dashboardView,
                                isCtaReview,
                                groupByParentEntities: isEntityClicked,
                            });
                        }}
                        onModelUpdated={onModelUpdated}
                        defaultColDef={defaultColumnDef}
                        noRowsOverlayComponent={GridNoRowsOverlay}
                        noRowsOverlayComponentParams={{ noRowsMessage, isError }}
                        onCellContextMenu={handleCellContextMenu}
                        suppressAsyncEvents={true}
                    />
                    {contextMenuPosition?.popUpVisible && (
                        <NewTabButton
                            closeContextMenu={closeContextMenu}
                            newTabLink={dashboardNewTabLink}
                            contextMenu={contextMenuPosition}
                        />
                    )}
                </GridLayout>
                <RowsInfo
                    isAvailable={!!apiRef.current}
                    isLoaded={!isLoading && !isError}
                    entitiesLength={entities?.length}
                    numberOfVisibleRows={numberOfRows}
                />
            </DashboardLayout>
        </StackPanel>
    );
}

const HeaderLayout = styled.div`
    display: grid;
    grid-gap: ${SPACING.XXS}px;
    padding-top: ${SPACING.XXS}px;
`;

const DashboardLayout = styled.div`
    padding-top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const GridLayout = styled.div`
    flex: 1;
    padding-top: ${SPACING.SM}px;
    height: 580px;
`;
