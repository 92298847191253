import { useEffect, useState } from 'react';

import { ThemeManager } from '@bxgrandcentral/controls';

const gridHeaderHight = 67;

export default function useGridStyleVars() {
    const [panelHeight, setPanelHeight] = useState(window.innerHeight - gridHeaderHight);

    const theme = ThemeManager.activeTheme.name === 'Dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';

    useEffect(() => {
        const handleResize = () => {
            setPanelHeight(window.innerHeight - gridHeaderHight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { panelHeight, theme };
}
