import { useLegalEntityCorporateTransparency } from 'modules/LegalEntityCreation/context/Provider';
import useHasUsRegistration from './use-has-us-registration';
import React from 'react';
import { EmailLink } from 'components';
import { NoUSRegistrationsOrExempt, USRegistrationsAndREIT } from 'models/shared/refData/CorporateTransparency';

type Props = {
    exemptKey?: number;
};

export default function useDisplayMessage({ exemptKey }: Props) {
    const context = useLegalEntityCorporateTransparency();
    const {
        state: {
            data: {
                values: { corporateTransparencyStatusInternal },
                noPendingValues,
            },
        },
    } = context;
    const hasUsRegistration = useHasUsRegistration();

    if (USRegistrationsAndREIT(hasUsRegistration, noPendingValues?.newStructureTypeId)) {
        return <p>Entity does not meet CTA exemption.</p>;
    }

    if (NoUSRegistrationsOrExempt(hasUsRegistration, corporateTransparencyStatusInternal, exemptKey)) {
        return <p>Entity appears to be exempt from CTA reporting.</p>;
    }
    return (
        <>
            <p>
                Entity does not meet CTA exemption. Please contact:{' '}
                <EmailLink
                    mailTo='LC-CorporateTransparencyAct@Blackstone.com'
                    label='LC-CorporateTransparencyAct@Blackstone.com'
                />
            </p>
        </>
    );
}
