import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { WaitingIndicator } from '@bxgrandcentral/controls';

export default function LoadingIndicator(props: ICellRendererParams) {
    const { value } = props;
    return value === 'Loading...' ? (
        <WaitingIndicator
            id={'spinner'}
            isVisible={true}
            isModalToShell={false}
            fontSize={10}
            width={40}
            margin={`-${SPACING.XS}px 0 0 0`}
        />
    ) : (
        <span>{value}</span>
    );
}
