import { CanDissolveEntityResponse } from './model';
import { GlobalState } from 'GlobalState';
import { Link } from 'react-router-dom';
import React from 'react';
import { SPACING } from 'Utilities/Layout';

export function getBalanceCheckInfoMessage(canDissolveEntityResponse: CanDissolveEntityResponse) {
    const message = (
        <>
            <p style={{ marginTop: 0 }}>
                There are balances on this entity. To dissolve this entity, balances must be cleared to zero.
            </p>
            <p>Please refer to the following dashboard(s) for more details on the associated balances:</p>
            <ul style={{ margin: 0, padding: 0 }}>
                {canDissolveEntityResponse.icBalanceReportUrl && (
                    <li>
                        <Link
                            to={{ pathname: canDissolveEntityResponse.icBalanceReportUrl }}
                            target='_blank'
                            style={{ display: 'block', marginBottom: `${SPACING.MD}px` }}>
                            Intercompany Balances associated with the Legal Entity
                        </Link>
                    </li>
                )}
                {canDissolveEntityResponse.leBalanceReportUrl && (
                    <li>
                        <Link to={{ pathname: canDissolveEntityResponse.leBalanceReportUrl }} target='_blank'>
                            Trial Balances associated with the Legal Entity
                        </Link>
                    </li>
                )}
            </ul>
        </>
    );

    return message;
}

export function showNoBalancesConfirmationPopup() {
    GlobalState.ShowMessageBox(
        'INFO',
        'There are no intercompany and trial balances associated with this entity. The entity will proceed to the next dissolution stage.',
        'Confirm',
        'Confirmation'
    );
}
