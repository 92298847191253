import { AuthorizedPersonModel } from '../../model';
import { getReferenceDataValueByKey } from 'modules/LegalEntityCreation/utils';
import { useMemo } from 'react';
import { useReferenceData } from 'api';

type Props = {
    data?: AuthorizedPersonModel[];
};

export default function useProcessedDataForGrid({ data = [] }: Props) {
    const {
        data: { SignatoryTitles },
    } = useReferenceData();

    const processedData = useMemo(
        () =>
            data.map((item) => {
                return {
                    ...item,
                    title: getReferenceDataValueByKey(SignatoryTitles, item.title),
                } as AuthorizedPersonModel;
            }),
        [data, SignatoryTitles]
    );

    return processedData;
}
