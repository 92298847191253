import { DocumentLayout, FileUploadContainer, PendingDocuments } from '../FileUpload.styled';
import { EffectiveDateProp, PendingDocument as PendingDocumentModel } from '../models';
import { Grid, StackPanel, TextBlock } from '@bxgrandcentral/controls';
import React, { Fragment, useMemo } from 'react';
import { convertToDocument, filterUploadedFiles } from '../utils';

import Dropzone from 'react-dropzone';
import FileUploadErrorMessage from './FileUploadErrorMessage';
import { GlobalState } from 'GlobalState';
import { KeyValue } from '../../../../models';
import PendingDocument from './PendingDocument';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { SPACING } from '../../../../Utilities/Layout';
import { Text } from 'components';
import { addPendingDocuments } from 'modules/LegalEntityCreation/context/actions/index';
import useColors from 'api/hooks/use-theme';

interface Props {
    entityOid: number;
    documents: PendingDocumentModel[];
    sectionName: string;
    isStampedDocumentUpload?: boolean;
    documentTypes?: KeyValue[];
    isEditable?: boolean;
    taxRegistrationId?: number;
    effectiveDate?: EffectiveDateProp;
    showStoredDocuments: boolean;
    errorMessage?: string;
    dispatch: any;
}

export default function FileField({
    entityOid,
    documents,
    documentTypes,
    sectionName,
    taxRegistrationId,
    isStampedDocumentUpload = false,
    effectiveDate = 'none',
    showStoredDocuments,
    errorMessage,
    dispatch,
}: Props) {
    const hasPendingDocuments = useMemo(() => documents.length > 0, [documents]);
    const headerProps = useMemo(() => ({ styleName: 'captionStyle', fontSize: 12 }), []);

    const colors = useColors();

    const onDrop = async (files: File[]) => {
        const { validFiles, filesAboveMaxUploadLimit, filesWithInvalidExtensions } = filterUploadedFiles(files);

        if (filesWithInvalidExtensions.length || filesAboveMaxUploadLimit.length) {
            await GlobalState.openDialog({
                title: 'Document upload',
                variant: 'warning',
                content: (
                    <FileUploadErrorMessage
                        filesAboveMaxUploadLimit={filesAboveMaxUploadLimit}
                        filesWithInvalidExtensions={filesWithInvalidExtensions}
                    />
                ),
                okButtonLabel: 'Close',
                isConfirmationDialog: false,
            });
        }

        addPendingDocuments(
            dispatch,
            sectionName,
            validFiles.map((file) =>
                convertToDocument({
                    file,
                    entityOid,
                    sectionName: isStampedDocumentUpload ? SECTION_NAMES.STAMPED : sectionName,
                    taxRegistrationId,
                })
            )
        );
    };

    return (
        <Grid>
            {hasPendingDocuments && (
                <>
                    <PendingDocuments colors={colors} useTopBorder={showStoredDocuments}>
                        <TextBlock text='Pending Documents' styleName='captionStyle' verticalAlignment='center' />
                        <StackPanel itemGap={SPACING.SM}>
                            <DocumentLayout hasEffectiveDate={effectiveDate !== 'none'}>
                                <TextBlock text='File Name' {...headerProps} />
                                <TextBlock text='Document Type' {...headerProps} />
                                {effectiveDate !== 'none' && <TextBlock text='Effective Date' {...headerProps} />}
                            </DocumentLayout>
                            {documents.map((document) => (
                                <Fragment key={document.id}>
                                    <PendingDocument
                                        document={document}
                                        documentTypes={documentTypes}
                                        sectionName={sectionName}
                                        effectiveDate={effectiveDate}
                                        dispatch={dispatch}
                                    />
                                </Fragment>
                            ))}
                        </StackPanel>
                        <Text variant='errorBold' textAlignment='center' horizontalAlignment='center'>
                            {errorMessage}
                        </Text>
                    </PendingDocuments>
                </>
            )}
            <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }: any) => (
                    <div {...getRootProps()}>
                        <FileUploadContainer colors={colors} style={{ cursor: 'pointer' }}>
                            <TextBlock
                                text='Click here or drag and drop your attachments'
                                styleName='captionStyle'
                                horizontalAlignment='center'
                                verticalAlignment='center'
                            />
                        </FileUploadContainer>
                        <input {...getInputProps()} />
                    </div>
                )}
            </Dropzone>
        </Grid>
    );
}
