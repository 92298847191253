import {
    CreateLegalEntityWorkflow,
    FinanceInfoWorkflow,
    ForeignRegistrationUpdateWorkflow,
    ForeignRegistrationWorkflow,
    LegalEntityUpdateWorkflow,
    OwnershipWorkflow,
    TaxClassificationWorkflow,
} from 'models/LegalEntityRequest/Workflow';
import { useEffect, useState } from 'react';

import { Task } from 'models/Workflow';

export type ChildWorkflowTaskType =
    | 'RequestTaskType'
    | 'UpdateRequestTaskType'
    | 'ReviewTransitionTaskType'
    | 'DocPrepTransitionTaskType'
    | 'ReviewDocPrepTaskType'
    | 'submitToApprovalTaskType'
    | 'CorporateTransparencyTaskType'
    | 'CTANotificationTaskType'
    | 'ActivateEntity'
    | 'TransitionToPostProcess1TaskType'
    | 'ResubmitToApproval'
    | 'UpdateDocPrepTaskKey'
    | 'UpdateRequestTaskType';

export type ChildWorkflowTask = Task & Record<keyof Pick<Task, 'taskType'>, ChildWorkflowTaskType>;

type Props = {
    taskType?: ChildWorkflowTaskType;
    workItem?:
        | TaxClassificationWorkflow
        | FinanceInfoWorkflow
        | OwnershipWorkflow
        | ForeignRegistrationUpdateWorkflow
        | ForeignRegistrationWorkflow
        | LegalEntityUpdateWorkflow
        | CreateLegalEntityWorkflow;
};

export default function useLastCompletedTask({ workItem, taskType }: Props) {
    const [lastPageUpdate, setLastPageUpdate] = useState<ChildWorkflowTask>();

    useEffect(() => {
        const anyWithdrawTask = workItem?.tasks?.some(
            (task: ChildWorkflowTask) => task.taskType === 'RequestTaskType' && task.exitCode === 'Withdraw'
        );
        if (workItem?.tasks && !anyWithdrawTask) {
            const lastCompletedTask = [...workItem?.tasks].reverse().find((task) => {
                return task.completed && task.taskType === taskType && task.exitCode !== 'Reject';
            });
            setLastPageUpdate(lastCompletedTask);
        }
    }, [lastPageUpdate, taskType, workItem]);

    return lastPageUpdate;
}
