import { BooleanField, ElasticSearchField, Select, Text, TextField } from 'components';
import { DoubleColumnLayout, HORIZONTAL_FORM_PADDING, SPACING } from 'Utilities/Layout';
import { InterestTypes, InterestUnit, LegalTypes } from 'models/LegalEntityRequest/DefaultValues';
import React, { useEffect, useMemo } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';
import { getKeysOf, getValuesOf } from 'Utilities/object';
import { isEqual, isNil } from 'lodash';
import {
    useLegalEntityOwnerShipDetail,
    useLegalEntityOwnerShipInformation,
} from 'modules/LegalEntityCreation/context/Provider';

import { EntitySubType } from 'models/LegalEntity/EntitySubType';
import { FormControl } from 'components/controls/controls.styled';
import { OwnershipDetailsModel } from '../../model';
import UpdateButton from './UpdateButton';
import { VALIDATION_MESSAGES } from 'modules/LegalEntityCreation/validation/utils';
import { hasElement } from 'Utilities/array';
import { isEmpty } from 'Utilities/Validations';
import { resetState } from 'modules/LegalEntityCreation/context/actions';
import useOwnershipEditedDetailValidation from 'modules/LegalEntityCreation/validation/use-ownership-edited-detail-validation';
import { useReferenceData } from 'api';

type Props = {
    addNewItem: (item: OwnershipDetailsModel) => void;
    closeSelectedElement: () => void;
    isNewFlow: boolean;
    setFormHasChanges: (isOpen: boolean) => void;
};

export default function OwnershipDetail({ addNewItem, closeSelectedElement, isNewFlow, setFormHasChanges }: Props) {
    const {
        state: {
            data: {
                values: { ownershipDetails = [], legalType },
            },
            isEditable,
        },
        setValue: setOwnershipDetailsValue,
    } = useLegalEntityOwnerShipInformation();

    const {
        state: {
            data: { values, noPendingValues, validationErrors, changes },
        },
        setValues,
        getValue,
        dispatch,
    } = useLegalEntityOwnerShipDetail();

    useOwnershipEditedDetailValidation({ legalType });

    const {
        data: {
            InterestClassType,
            InterestUnitType,
            InterestTypes: InterestTypesRefData,
            ReportingType,
            Currency,
            AxiomShareClass: ShareClassRefData,
        },
    } = useReferenceData();

    const {
        key,
        index,
        interestUnit,
        interestClass,
        interestType,
        numberOfUnits,
        ownerShip,
        parentEntity,
        reportingType,
        votingRight,
        hasPendingChange,
    } = values;

    const isEmptyOwnership = useMemo(
        () =>
            getValuesOf({
                interestUnit,
                interestClass,
                interestType,
                numberOfUnits,
                ownerShip,
                parentEntity,
                reportingType,
                votingRight,
            }).every((value) => isNil(value)),
        [interestClass, interestType, interestUnit, numberOfUnits, ownerShip, parentEntity, reportingType, votingRight]
    );

    const hasRequiredFieldErrors = useMemo(
        () => getKeysOf(validationErrors).find((key) => validationErrors[key] === VALIDATION_MESSAGES.REQUIRED_FIELD),
        [validationErrors]
    );

    const hasNoValidationError = useMemo(
        () =>
            isNil(validationErrors.interestUnit) &&
            isNil(validationErrors.interestClass) &&
            isNil(validationErrors.interestType) &&
            isNil(validationErrors.numberOfUnits) &&
            isNil(validationErrors.ownerShip) &&
            isNil(validationErrors.parentEntity) &&
            isNil(validationErrors.reportingType) &&
            isNil(validationErrors.votingRight),
        [validationErrors]
    );

    const addChanges = () => {
        if (values.key || !isNewFlow) {
            const updated = ownershipDetails.map((details) => {
                if (details.index === values.index) {
                    return values;
                }

                return details;
            });

            setOwnershipDetailsValue('ownershipDetails', updated);
            closeSelectedElement();
        } else {
            addNewItem(values);
        }
        resetState(dispatch);
    };

    useEffect(() => {
        setFormHasChanges(!isEmpty(changes));
    }, [changes, values]);

    const currencyOptions = useMemo(
        () => Currency?.map((currency) => ({ ...currency, Value: `${currency.ShortValue} - ${currency.Value}` })),
        [Currency]
    );

    const interestUnits = useMemo(() => {
        switch (legalType) {
            case LegalTypes.Corporation:
            case LegalTypes.SARLSAGmbH:
                return InterestUnitType?.filter(({ Key }) => Key === InterestUnit.Share);
            case LegalTypes.LimitedPartnership:
            case LegalTypes.LimitedLiabilityCompany:
            case LegalTypes.SCSp:
                return InterestUnitType?.filter(({ Key }) => Key !== InterestUnit.Share);
            default:
                return InterestUnitType;
        }
    }, [InterestUnitType, legalType]);

    const interestTypes = useMemo(() => {
        switch (legalType) {
            case LegalTypes.Corporation:
            case LegalTypes.SARLSAGmbH:
                return InterestTypesRefData?.filter(({ Key }) => Key === InterestTypes.Shareholder);
            case LegalTypes.LimitedPartnership:
            case LegalTypes.SCSp:
                return InterestTypesRefData?.filter(({ Key }) =>
                    [InterestTypes.GeneralPartner, InterestTypes.LimitedPartner].includes(Key)
                );
            case LegalTypes.LimitedLiabilityCompany:
                return InterestTypesRefData?.filter(({ Key }) =>
                    [InterestTypes.SoleMember, InterestTypes.ManagingMember, InterestTypes.Member].includes(Key)
                );
            default:
                return InterestTypesRefData;
        }
    }, [InterestTypesRefData, legalType]);

    const isPendingChange = (newValue?: any, noPendingValue?: any) => {
        return !isEditable && !isNil(noPendingValues) ? !isEqual(newValue, noPendingValue) : undefined;
    };

    const itemIsSetForDeletion = useMemo(() => {
        const editedItem = ownershipDetails.find((item) => item.index === index);

        return editedItem?.isDeleted;
    }, [ownershipDetails]);

    const updateButtonAvailable = isEditable && !itemIsSetForDeletion;
    const isNewItem = !key || (hasPendingChange && !noPendingValues);

    return (
        <StackPanel padding={HORIZONTAL_FORM_PADDING}>
            {isNewItem && (
                <TextBlock styleName='heading4Style' margin={`0 0 ${SPACING.SM}px 0 `}>
                    New Owner
                </TextBlock>
            )}
            <DoubleColumnLayout isDisabled={itemIsSetForDeletion}>
                <StackPanel itemGap={SPACING.XS}>
                    <FormControl className='form'>
                        <ElasticSearchField
                            label='Parent Entity'
                            labelToolTip='Child legal entity to owner legal entity'
                            searchType={EntitySubType.LegalEntity}
                            selectedEntityOId={parentEntity}
                            onEntitySelected={(result) => {
                                setValues({
                                    parentEntity: result?.entityOId,
                                    relatedEntityName: result?.primaryName,
                                });
                            }}
                            isReadOnly={!isEditable}
                            canClearSelection
                            isRequired
                            isPendingChange={
                                noPendingValues && isPendingChange(parentEntity, noPendingValues?.parentEntity)
                            }
                            {...(isEditable && { validationError: validationErrors.parentEntity })}
                        />
                    </FormControl>
                    <Select
                        label='Interest Unit'
                        labelToolTip={{ component: 'The type of units for ownership interest in a given entity.' }}
                        itemsSource={interestUnits}
                        {...getValue('interestUnit')}
                    />
                    <Select
                        label='Interest Class'
                        labelToolTip={{
                            component:
                                'The share class or equivalent unit type for ownership interest in a given entity',
                        }}
                        itemsSource={InterestClassType}
                        {...getValue('interestClass')}
                    />
                    <TextField
                        label='Number of Units'
                        labelToolTip='The units issued for ownership in a given entity'
                        {...getValue('numberOfUnits')}
                    />
                    <TextField label='Nominal Value' {...getValue('nominalValue')} />
                </StackPanel>
                <StackPanel itemGap={SPACING.XS}>
                    <TextField
                        label='Ownership %'
                        labelToolTip='Parent legal entity’s ownership percentage'
                        {...getValue('ownerShip')}
                    />
                    <Select
                        label='Reporting Type'
                        labelToolTip={{ component: 'Legal Entity reporting type' }}
                        itemsSource={ReportingType}
                        {...getValue('reportingType')}
                    />
                    <Select
                        label='Interest Type'
                        labelToolTip={{ component: 'Owner hierarchy interest type' }}
                        itemsSource={interestTypes}
                        isRequired
                        {...getValue('interestType')}
                    />
                    <Select label='Share Class Type' itemsSource={ShareClassRefData} {...getValue('shareClass')} />
                    <Select label='Currency' itemsSource={currencyOptions} {...getValue('currency')} />
                    {!hasElement(
                        [LegalTypes.LimitedPartnership, LegalTypes.LimitedLiabilityCompany, LegalTypes.SCSp],
                        legalType
                    ) && (
                        <BooleanField
                            label='Voting Rights'
                            labelToolTip={{
                                component: 'Whether or not this owner has voting rights',
                            }}
                            {...getValue('votingRight')}
                        />
                    )}
                </StackPanel>
            </DoubleColumnLayout>
            {(hasRequiredFieldErrors || isEmptyOwnership) && (
                <StackPanel orientation='horizontal' styleOverrides={{ justifyContent: 'center' }}>
                    <Text variant='errorBold' horizontalAlignment='center' textAlignment='center'>
                        Please fill out all required fields to save the Ownership detail
                    </Text>
                </StackPanel>
            )}
            {updateButtonAvailable && (
                <StackPanel itemGap={SPACING.LG} orientation='horizontal' styleOverrides={{ alignSelf: 'flex-end' }}>
                    <UpdateButton
                        addChanges={addChanges}
                        isEnabled={hasNoValidationError && !isEmptyOwnership}
                        text={isNewFlow ? 'Add' : 'Update Ownership'}
                    />
                </StackPanel>
            )}
        </StackPanel>
    );
}
