import { ColDef, TextFilter } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';

import DissolveCellRenderer from 'modules/RequestsDashboard/components/Grid/components/DissolveCellRenderer';
import EditCellRenderer from 'modules/RequestsDashboard/components/Grid/components/EditCellRenderer';
import EntityOIdCellRenderer from 'modules/RequestsDashboard/components/Grid/components/EntityOIdCellRenderer';
import { GridApiRef } from 'hooks/use-column-configuration';
import { LegalEntityStatus } from 'models/LegalEntityRequest/LegalEntityStatus';
import LoadingIndicator from 'modules/RequestsDashboard/components/Grid/components/GridLoadingIndicator';
import { SearchResultItem } from '../model';
import SetFilter from 'modules/RequestsDashboard/components/SetFilter/SetFilter';

const fieldsWithSetFilter = ['businessUnit', 'subBusinessUnit', 'entityStatus'];

type Props = {
    legalEntityStatuses: LegalEntityStatus[];
    data?: SearchResultItem[];
    gridApi?: GridApiRef;
};
export default function useMatchEntitiesDashboardGridConfig({ legalEntityStatuses, data, gridApi }: Props) {
    const getFilterValuesFromData = useCallback(
        (field: keyof SearchResultItem, data: SearchResultItem[]) => {
            const filterValuesFromData = Array.from(new Set(data.map((e) => e[field]))).sort();
            const filterValuesFromStatuses = Array.from(
                new Set(legalEntityStatuses.map((e) => e.entityStatusName))
            ).sort();

            return field === 'entityStatus' ? filterValuesFromStatuses : filterValuesFromData;
        },
        [legalEntityStatuses]
    );

    const generateSetFilter = useCallback(
        (field?: keyof SearchResultItem, data?: SearchResultItem[]) => {
            if (!field || !data) {
                return { filter: false };
            }

            const values = getFilterValuesFromData(field, data);

            return values.length > 0
                ? {
                      filter: SetFilter,
                      filterParams: {
                          values,
                          maxHeight: '400px',
                          api: gridApi,
                          colDef: { field },
                      },
                  }
                : {};
        },
        [data, legalEntityStatuses, getFilterValuesFromData]
    );

    const matchLegalEntitiesColumnDefs: ColDef[] = [
        {
            field: 'edit',
            headerName: 'Edit',
            suppressMovable: true,
            filter: false,
            resizable: false,
            minWidth: 70,
            maxWidth: 70,
            cellRenderer: EditCellRenderer,
        },
        {
            field: 'entityOId',
            headerName: 'Entity ID',
            suppressMovable: true,
            resizable: false,
            minWidth: 150,
            maxWidth: 150,
            cellRenderer: EntityOIdCellRenderer,
            cellRendererParams: {
                redirectTo: 'LegalEntityInformation',
            },
            sortingOrder: ['asc', 'desc'],
            sortable: true,
            filter: TextFilter,
            filterParams: {
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'mdmEntityOId',
            headerName: 'Legacy ID',
            suppressMovable: true,
            resizable: false,
            minWidth: 150,
            maxWidth: 150,
            cellClass: 'cell-mdmEntityOId',
            sortable: true,
            filter: TextFilter,
            filterParams: {
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'primaryName',
            headerName: 'Entity Name',
            cellClass: 'cell-primaryName',
            resizable: true,
            sortable: true,
            filter: TextFilter,
            filterParams: {
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'shortName',
            headerName: 'Entity Short Name',
            cellClass: 'cell-shortName',
            resizable: true,
            sortable: true,
            filter: TextFilter,
            filterParams: {
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'entityStatus',
            headerName: 'Entity Status',
            cellClass: 'cell-entityStatus',
            sortable: true,
            resizable: true,
            valueGetter: (params) => {
                const entityStatus = params.data.entityStatus;
                return entityStatus || 'Loading...';
            },
            cellRenderer: LoadingIndicator,
        },
        {
            field: 'businessUnit',
            headerName: 'Business Unit',
            cellClass: 'cell-businessUnit',
            sortable: true,
            resizable: true,
        },
        {
            field: 'subBusinessUnit',
            headerName: 'Sub-Business Unit',
            cellClass: 'cell-subBusinessUnit',
            sortable: true,
            resizable: true,
        },
        {
            field: 'hqAddress',
            headerName: 'Headquarter Address',
            sortable: true,
            resizable: true,
            cellClass: 'cell-hqAddress',
            filter: TextFilter,
            filterParams: {
                suppressAndOrCondition: true,
            },
        },
    ];

    matchLegalEntitiesColumnDefs.splice(1, 0, {
        field: 'Dissolve',
        headerName: 'Dissolve',
        suppressMovable: true,
        filter: false,
        resizable: false,
        minWidth: 90,
        maxWidth: 90,
        cellRenderer: DissolveCellRenderer,
    });

    const columnDefs: ColDef<SearchResultItem>[] = useMemo(() => {
        const newColDefs = matchLegalEntitiesColumnDefs.map((column: ColDef<SearchResultItem>) => {
            const { field = '' } = column;
            return {
                ...column,
                ...(fieldsWithSetFilter.includes(field) && {
                    ...generateSetFilter(field as keyof SearchResultItem, data),
                }),
            };
        });

        return newColDefs;
    }, [matchLegalEntitiesColumnDefs, data, generateSetFilter, gridApi, legalEntityStatuses, getFilterValuesFromData]);

    return { matchLegalEntitiesColumnDefs: columnDefs };
}
