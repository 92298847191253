import { EmailLink, Text } from 'components';

import React from 'react';
import { SPACING } from 'Utilities/Layout';

type Props = {
    isVisible?: boolean;
};

export default function ContactInfo({ isVisible }: Props) {
    if (!isVisible) {
        return null;
    }

    return (
        <Text verticalAlignment='center' margin={`0 ${SPACING.XL}px`} styleOverrides={{ fontStyle: 'italic' }}>
            Please reach out to{' '}
            <EmailLink
                mailTo='LC-CorporateTransparencyAct@Blackstone.com'
                label='LC-CorporateTransparencyAct@Blackstone.com'
            />{' '}
            for any questions related to the Corporate Transparency Act.
        </Text>
    );
}
