import { useCallback, useEffect, useState } from 'react';

import { ILegalEntityService } from 'services/interfaces';
import { LegalEntityStatus } from 'models/LegalEntityRequest/LegalEntityStatus';
import { SearchResultItem } from '../model';
import { ServiceLocator } from '@bxgrandcentral/shell';

type Props = {
    searchResults: SearchResultItem[];
};

export default function useProcessedSearchResults({ searchResults }: Props) {
    const legalEntityService = ServiceLocator.container.resolve(ILegalEntityService);

    const [legalEntityStatuses, setLegalEntityStatuses] = useState<LegalEntityStatus[]>([]);
    const [processedSearchResults, setProcessedSearchResults] = useState<SearchResultItem[]>(searchResults);

    const getLegalEntityStatuses = useCallback(async () => {
        const statuses = await legalEntityService.GetEntiyStatuses(searchResults.map((result) => result.entityOId));

        setLegalEntityStatuses(statuses);
    }, [searchResults]);

    useEffect(() => {
        getLegalEntityStatuses();
    }, [searchResults]);

    useEffect(() => {
        const processedResults = searchResults.map((item) => {
            const processedItem = { ...item };

            processedItem.entityStatus =
                legalEntityStatuses.find(({ entityOId }) => entityOId === item.entityOId)?.entityStatusName || '';

            return processedItem;
        });

        setProcessedSearchResults(processedResults);
    }, [searchResults, legalEntityStatuses]);

    return { processedSearchResults, legalEntityStatuses };
}
