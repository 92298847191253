import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { StackPanel } from '@bxgrandcentral/controls';
import { Text } from 'components';
import { dateRanges } from '../DateRangePicker/components/model';
import { useAppContext } from 'context/app-context';

export default function DateRangeInfo() {
    const {
        state: {
            dashboard: { fromDate, toDate, activeDateRange },
        },
    } = useAppContext();

    const { format } = new Intl.DateTimeFormat('en-US');

    return (
        <StackPanel
            orientation='horizontal'
            verticalAlignment='center'
            margin={`-4px ${SPACING.MD}px 0 ${SPACING.SM}px`}>
            {activeDateRange === dateRanges.ALL_DATA ? (
                <Text variant='body-small'>Show All Data </Text>
            ) : (
                <>
                    <Text variant='body-small'>Showing Entity Last Updated </Text>
                    {fromDate ? (
                        <>
                            <Text variant='body-small'>From </Text>
                            <Text variant='body-small-bold'>{format(fromDate)}</Text>
                        </>
                    ) : (
                        <Text variant='body-small'>up</Text>
                    )}
                    <Text variant='body-small'> to </Text>
                    <Text variant='body-small-bold'>{toDate ? format(toDate) : 'Today'}</Text>
                </>
            )}
        </StackPanel>
    );
}
