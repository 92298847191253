import { OwnershipDetailsModel } from '../../model';
import { getReferenceDataValueByKey } from 'modules/LegalEntityCreation/utils';
import { useMemo } from 'react';
import { useReferenceData } from 'api';

type Props = {
    data?: OwnershipDetailsModel[];
};

export type OwnershipDetailsProcessedForGrid = Omit<OwnershipDetailsModel, 'votingRight'> & {
    votingRight?: string;
};

export default function useProcessedDataForGrid({ data = [] }: Props) {
    const {
        data: { InterestTypes, Currency, AxiomShareClass },
    } = useReferenceData();

    const processedData = useMemo(
        () =>
            data.map((item) => {
                return {
                    ...item,
                    interestType: getReferenceDataValueByKey(InterestTypes, item.interestType),
                    currency: getReferenceDataValueByKey(Currency, item.currency, 'ShortValue'),
                    shareClass: getReferenceDataValueByKey(AxiomShareClass, item.shareClass),
                    votingRight: item.votingRight ? 'Yes' : 'No',
                } as OwnershipDetailsProcessedForGrid;
            }),
        [data, InterestTypes, Currency, AxiomShareClass]
    );

    return processedData;
}
