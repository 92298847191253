import { Capabilities, RequestStepsView } from '../RequestStepsView';
import React, { useMemo } from 'react';
import { setResetLegalEntityCreationForm, useAppContext } from 'context/app-context';

import { DISSOLUTION_STATUS } from 'models/LegalEntityRequest/Workflow';
import { LegalEntityCorporateTransparencyData } from 'modules/LegalEntityCreation/LegalEntityCorporateTransparency/model';
import LegalEntityCorporateTransparencySection from 'modules/LegalEntityCreation/LegalEntityCorporateTransparency/LegalEntityCorporateTransparencySection';
import { isEmpty } from 'lodash';
import useApiServices from '../hooks/use-api-services';
import { useCreationViewContext } from '../context/creation-view-context';
import useHasCapability from '../hooks/use-has-capability';

export default function CorporateTransparencyStepRenderer() {
    const { completeTask, saveSection } = useApiServices();

    const { dispatch: appContextDispatch } = useAppContext();

    const hasCapability = useHasCapability();

    const {
        state: {
            isWorkItemsRefreshing,
            sections,
            noPendingSections,
            entityCreationWorkItem: workItem,
            selectedPanel,
            editWorkFlow,
            legalEntityDissolutionParentWorkItem,
        },
    } = useCreationViewContext();

    const isDissolutionInProcess = useMemo(() => {
        return legalEntityDissolutionParentWorkItem?.workItemStatus === DISSOLUTION_STATUS.IN_PROCESS;
    }, [legalEntityDissolutionParentWorkItem]);

    const commonProps = {
        data: sections.CorporateTransparency,
        noPendingData: noPendingSections.CorporateTransparency,
        workItem,
    };

    if (isEmpty(commonProps.data)) {
        return null;
    }

    const isCTARequestPending = !!workItem?.tasks?.some(
        (task) => task.taskType === 'CorporateTransparencyTaskType' && task.status === 'New'
    );

    const isCTARequestComplete =
        !isCTARequestPending &&
        !!workItem?.tasks?.some((task) => task.taskType === 'CTANotificationTaskType' && task.status === 'Complete');

    const isReviewMode = hasCapability(Capabilities.approveCorporateTransparency) && isCTARequestComplete;

    if (isDissolutionInProcess || isWorkItemsRefreshing || (!selectedPanel?.hasActions && !editWorkFlow)) {
        return <LegalEntityCorporateTransparencySection mode='ReadOnly' {...commonProps} isReviewMode={isReviewMode} />;
    }

    switch (true) {
        case isCTARequestPending:
            return (
                <LegalEntityCorporateTransparencySection
                    mode='Create'
                    onSave={async (dataToSave: Partial<LegalEntityCorporateTransparencyData>) =>
                        saveSection(sections, 'CorporateTransparency', dataToSave)
                    }
                    onCompleteTask={async (task, exitCode, rejectReason) => {
                        if (workItem) {
                            await completeTask(
                                workItem,
                                task,
                                exitCode,
                                RequestStepsView.Approval,
                                rejectReason,
                                {
                                    skipRequestNotesUpdate: true,
                                },
                                5000
                            );
                            setResetLegalEntityCreationForm(appContextDispatch, true);
                        }
                    }}
                    {...commonProps}
                />
            );
        case isCTARequestComplete:
            return (
                <LegalEntityCorporateTransparencySection
                    mode='Edit'
                    onSave={async (dataToSave: Partial<LegalEntityCorporateTransparencyData>) =>
                        saveSection(sections, 'CorporateTransparency', dataToSave)
                    }
                    isReviewMode
                    {...commonProps}
                />
            );
        default:
            return <LegalEntityCorporateTransparencySection mode='ReadOnly' {...commonProps} />;
    }
}
