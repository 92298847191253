import LegalEntityCorporateTransparency from './LegalEntityCorporateTransparency';
import { LegalEntityCorporateTransparencyProps } from './model';
import { LegalEntityCorporateTransparencyProvider } from '../context/Provider';
import React from 'react';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import useCorporateTransparencyAutopopulateData from './hooks/use-corporaty-transparency-autopopulate-data';

export default function LegalEntityCorporateTransparencySection(props: LegalEntityCorporateTransparencyProps) {
    const { calculateDefaultValues } = useCorporateTransparencyAutopopulateData();
    return (
        <LegalEntityCorporateTransparencyProvider
            storedValues={props.data}
            mode={props.mode}
            calculateDefaultValues={calculateDefaultValues}
            noPendingValues={props.noPendingData}
            requestStep={RequestStepsView.Corporate_Transparency}>
            <LegalEntityCorporateTransparency {...props} />
        </LegalEntityCorporateTransparencyProvider>
    );
}
