import { useLegalEntityCorporateTransparency } from 'modules/LegalEntityCreation/context/Provider';
import { useMemo } from 'react';
import useHasUsRegistration from './use-has-us-registration';
import { NoUSRegistrationsOrExempt, USRegistrationsAndREIT } from 'models/shared/refData/CorporateTransparency';

type Props = {
    exemptKey?: number;
};

export default function useTextVariant({ exemptKey }: Props) {
    const context = useLegalEntityCorporateTransparency();
    const {
        state: {
            data: {
                values: { corporateTransparencyStatusInternal },
                noPendingValues,
            },
        },
    } = context;
    const hasUsRegistration = useHasUsRegistration();

    const variant = useMemo(() => {
        if (USRegistrationsAndREIT(hasUsRegistration, noPendingValues?.newStructureTypeId)) {
            return 'error';
        }
        if (NoUSRegistrationsOrExempt(hasUsRegistration, corporateTransparencyStatusInternal, exemptKey)) {
            return 'success';
        }
        return 'error';
    }, [corporateTransparencyStatusInternal, exemptKey, hasUsRegistration]);

    return variant;
}
