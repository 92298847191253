import { isUnitedStates } from 'models';
import { useLegalEntityCorporateTransparency } from 'modules/LegalEntityCreation/context/Provider';
import { useMemo } from 'react';

export default function useHasUsRegistration() {
    const {
        state: {
            data: { noPendingValues },
        },
    } = useLegalEntityCorporateTransparency();

    const hasUsRegistration = useMemo(() => {
        const registrations = [
            ...(noPendingValues?.domesticRegistration ? noPendingValues.domesticRegistration : []),
            ...(noPendingValues?.foreignRegistrations ? noPendingValues.foreignRegistrations : []),
        ];

        const usRegistration = registrations.find(({ domesticCountry }) => isUnitedStates(domesticCountry));

        return !!usRegistration;
    }, [noPendingValues?.domesticRegistration, noPendingValues?.foreignRegistrations]);

    return hasUsRegistration;
}
