import { CreateLegalEntityWorkflow } from '../../../models/LegalEntityRequest/Workflow';

export const filterScopes = (scope: string, scopes?: string[]) => {
    return (
        scopes?.reduce((results: string[], value: string) => {
            if (value.split(':')[0] === scope) results.push(value.split(':')[1]);
            return results;
        }, []) ?? []
    );
};

export const filterFRScopes = (scope: string, scopes?: string[]) => {
    return (
        scopes?.reduce((results: string[], value: string) => {
            if (value.split(':')[0] === scope && value.split(':')[1] === 'ForeignRegistration')
                results.push(value.split(':')[1]);
            return results;
        }, []) ?? []
    );
};

export const isInformationStepEnabled = (
    workitem?: CreateLegalEntityWorkflow | undefined,
    legalEntityOId?: number | undefined
) => {
    // We are showing the information page for entities created in MDM (has no workitemState, has legalEntityOID),
    // and not showing for new entity creation (no workitemState, no legalEntityOID)
    if (!workitem) {
        return !!legalEntityOId;
    }

    // We are not showing the information page if the entity is not in the required state
    return !!workitem?.tasks?.some((task) => task.taskType === 'ActivateEntity' && task.status === 'Complete');
};

export const isDissolutionStepEnabled = (
    workitem?: CreateLegalEntityWorkflow | undefined,
    legalEntityOId?: number | undefined
) => {
    if (!legalEntityOId) {
        return false;
    }

    return (
        !!workitem?.tasks?.some((task) => task.taskType === 'ActivateEntity' && task.status === 'Complete') || !workitem
    );
};
