export enum SubBusinessUnit {
    BPP = 15642538,
    BREP = 15642539,
    BREDS = 15642541,
    BRESSA = 15642540,
    BXMT = 15642543,
    BREIT = 15642542,
    RE = 57202,
    GPA_Seg = 10015601,
}
