import { LegalEntityRequest } from 'models';
import { convertToLocalDate } from 'Utilities/date';
import { getParentProcessValue } from './components/Grid/model';

export const preProcessFields = (data: LegalEntityRequest[]) => {
    const ids = data.reduce(
        (acc, curr) => {
            if (curr.isParent) {
                acc.parentIDs.push(curr.entityOId);
            } else {
                acc.childIDS.push(curr.entityOId);
            }
            return acc;
        },
        {
            parentIDs: [] as Number[],
            childIDS: [] as Number[],
        }
    );

    return data.map((entityRequest) => {
        return {
            ...entityRequest,
            parentProcess: getParentProcessValue({
                value: entityRequest.isStandalone,
                data: entityRequest,
            }),
            isParent:
                entityRequest.workItemType === 'LegalEntityCreationWorkflow' ||
                entityRequest.workItemType === 'DissolutionParentWorkflow',
            hasChildren: entityRequest.isParent && ids.childIDS.includes(entityRequest.entityOId),
            isChild:
                ids.parentIDs.includes(entityRequest.parentEntityOId) ||
                (entityRequest.isStandalone && ids.parentIDs.includes(entityRequest.entityOId)),
        };
    });
};

export const dateFieldComparator = (dateFilterValueRaw: Date, cellValueRaw: Date) => {
    const dateFilterValue = dateFilterValueRaw ? convertToLocalDate(dateFilterValueRaw) : undefined;
    const cellValueDate = cellValueRaw ? convertToLocalDate(cellValueRaw) : undefined;

    if (!dateFilterValue || !cellValueDate) {
        return;
    }

    dateFilterValue.setMilliseconds(0);
    dateFilterValue.setMinutes(0);
    dateFilterValue.setSeconds(0);
    dateFilterValue.setHours(0);

    cellValueDate.setMilliseconds(0);
    cellValueDate.setMinutes(0);
    cellValueDate.setSeconds(0);
    cellValueDate.setHours(0);

    const dateFilter = dateFilterValue.getTime();
    const cellValue = cellValueDate.getTime();

    if (dateFilter === cellValue) {
        return 0;
    }

    if (cellValue < dateFilter) {
        return -1;
    }

    if (cellValue > dateFilter) {
        return 1;
    }
};
