import { getAlternateNames } from 'components/controls/ElasticSearchField/ElasticSearchField';
import { SearchEntity } from 'models';
import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'Utilities/Validations';

export const ScrollableTooltip = styled.div`
    height: 300px;
    overflow: auto;
`;
export const StructureTypeTooltip = (): React.ReactNode => {
    return (
        <ScrollableTooltip>
            <div>
                Admin: Entity formed for Blackstone corporate administrative purpose, not for investment. e.g.
                Blackstone Administrative Services Partnership L.P.
            </div>
            <br />
            <div>
                Advisor: SEC Registered Investment Advisor (RIA) is engaged in the business of providing investment
                advice to others for compensation, e.g. Blackstone Management Partners L.L.C.
            </div>
            <br />
            <div>
                GP: Entity formed to make decisions concerning the purchase and sale of the fund's investments and/or
                earn carried interest.
            </div>
            <br />
            <div>
                SBS: Entity formed to raise capital internally from Blackstone employees and invest alongside the
                Blackstone funds, e.g. entities named with "SBS", "SMD", "family", "ESC", etc.
            </div>
            <br />
            <div>
                Fund- Main: Fund entities that 3rd party investors subscribe to, e.g. entity named with the fund name or
                special note followed by the fund name, e.g. fund name followed by “TE” for pension fund investors.
            </div>
            <br />
            <div>
                Co-Invest: Fund entities that 3rd party investors subscribe to formed alongside the main fund(s) that
                enables investors to invest directly under different terms from the main fund.
            </div>
            <br />
            <div>
                Co-Invest Discretionary: Fund entities that 3rd party investors subscribe to where their commitment can
                be funded at the discretion of the investor (i.e. Blackstone has no recourse to drawdown their
                commitments without further elections from the investor).
            </div>
            <br />
            <div>
                AIV: Alternative Investment Vehicles to the main funds that accommodate different structuring needs of
                the fund in connection with one or more investments so investors can take advantage of efficient
                structures to hold specific assets, e.g. entity named with "NQ", "Cayman NQ" etc.
            </div>
            <br />
            <div>
                Sleeve (BTO only)*: A sub-fund differentiated by a specific investment policy, objective or currency.
            </div>
            <br />
            <div>
                Blocker - Leveraged: Entity classified as a corporation for U.S. federal income tax purposes to relieve
                investors from a US filing obligation, which investors contribute with combination of equity and debt.
            </div>
            <br />
            <div>
                Blocker - Unleveraged: Entity classified as a corporation for U.S. federal income tax purposes to
                relieve investors from a US filing obligation, which the investors contribute with equity.
            </div>
            <br />
            <div>
                SPV: Special Purpose Vehicle formed specific for deal(s), e.g. entity named with "holdings", "Holdco",
                "Parents", "Intermediate", "BidCo", "Merger" etc.
            </div>
            <br />
            <div>
                SPV - Aggregator*: SPV that aggregate capital from multiple Blackstone funds for specific deals, e.g.
                entity named with "holdings", "Holdco", "Aggregator” etc.
            </div>
            <br />
            <div>
                REIT: Real estate investment trust (REIT) is a company owning and typically operating real estate which
                generates income.
            </div>
        </ScrollableTooltip>
    );
};

export const AdministeredByTooltip = (): React.ReactNode => {
    return (
        <div>
            <span>
                Specify who will manage books and records of an entity. This selection will also determine the BX Entity
                / Portfolio Company / Not a BX Entity flags as per the following logic:
            </span>
            <ul>
                <li>If Admin By is "External Admin" or "Lux" = "Not a BX Entity"</li>
                <li>If Admin By is "Portfolio Company" = "Port Co"</li>
                <li>Else "Is BX Entity"</li>
            </ul>
        </div>
    );
};

export const AlternateNamesTooltip = (entity: SearchEntity): React.ReactNode => {
    return (
        <div>
            <div>
                <strong>{entity.primaryName}</strong>
            </div>
            <br />
            {!isEmpty(entity.shortName) && entity.shortName !== entity.primaryName && (
                <div>
                    <div>
                        <strong>Short Name: </strong>
                        {entity.shortName}
                    </div>
                    <br />
                </div>
            )}
            {!isEmpty(getAlternateNames(entity)) && (
                <div>
                    <div>
                        <strong>Altarnate Names: </strong> {getAlternateNames(entity)}
                    </div>
                    <br />
                </div>
            )}
        </div>
    );
};
