import { DateRangeLabels, dateRanges } from './components/model';
import {
    DateRangePickerDateTimeFieldContainer,
    DateRangePickerDropdown,
    DateRangePickerDropdownFooter,
    Button as ToolbarButton,
} from '../components.styled';
import { DateTimeField, GhostButton, Text } from 'components';
import { FontIcon, Grid, StackPanel } from '@bxgrandcentral/controls';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { setDashboardDateRange, useAppContext } from 'context/app-context';

import { SPACING } from 'Utilities/Layout';
import ToggleButton from './components/ToggleButton/ToggleButton';
import { constants } from 'constants/env-config';
import { isNil } from 'lodash';
import styled from 'styled-components';
import { subtractDays } from 'Utilities/date';
import useColors from 'api/hooks/use-theme';

type Props = {
    onRefresh: () => void;
    styleOverrides?: CSSProperties;
    isEnabled: boolean;
    iconTooltipText?: string;
};

export default function DateRangePicker({ onRefresh, styleOverrides, isEnabled, iconTooltipText }: Props) {
    const {
        state: {
            dashboard: { fromDate: storedFromDate, toDate: storedToDate, activeDateRange: storedActiveDateRange },
        },
        dispatch,
    } = useAppContext();

    const colors = useColors();

    const [isOpen, setIsOpen] = useState(false);

    const [activeDateRange, setActiveDateRange] = useState<DateRangeLabels | null>(dateRanges.LAST_N_DAYS);

    const [fromDate, setFromDate] = useState<Date>();
    const [toDate, setToDate] = useState<Date>();

    const isFromDateLaterThanToDate = useMemo(
        () => !isNil(fromDate) && !isNil(toDate) && fromDate > toDate,
        [fromDate, toDate]
    );

    useEffect(() => {
        setFromDate(storedFromDate);
        setToDate(storedToDate);
        setActiveDateRange(storedActiveDateRange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <StackPanel styleOverrides={{ ...styleOverrides }}>
            <ToolbarButton
                onClick={() => isEnabled && setIsOpen(!isOpen)}
                colors={colors}
                {...(!isEnabled && { className: 'disabled' })}>
                <FontIcon
                    iconName='DateTime'
                    foregroundColor={colors.foregroundColor}
                    fontSize={20}
                    cursor='pointer'
                    toolTip={iconTooltipText}
                />
            </ToolbarButton>
            {isOpen && (
                <DateRangePickerDropdown colors={colors}>
                    <Grid itemGap={SPACING.MD} styleOverrides={{ flex: 1 }}>
                        <Header>
                            <Text variant='body'>Show Data for</Text>
                            <FontIcon
                                iconName='ChromeClose'
                                onClick={() => setIsOpen(false)}
                                horizontalAlignment='right'
                                cursor='pointer'
                                styleOverrides={{ display: 'flex', alignItems: 'center' }}
                            />
                        </Header>
                        <ToggleContainer>
                            <ToggleButton
                                label={dateRanges.LAST_N_DAYS}
                                activeDateRange={activeDateRange}
                                variant='left'
                                onClick={() => {
                                    setActiveDateRange(dateRanges.LAST_N_DAYS);
                                    setFromDate(subtractDays(new Date(), parseInt(constants.DEFAULT_NDAYS_DASHBOARD)));
                                    setToDate(undefined);
                                }}
                            />
                            <ToggleButton
                                label={dateRanges.ALL_DATA}
                                activeDateRange={activeDateRange}
                                variant='right'
                                onClick={() => {
                                    setActiveDateRange(dateRanges.ALL_DATA);
                                    setFromDate(undefined);
                                    setToDate(undefined);
                                }}
                            />
                        </ToggleContainer>
                        <StackPanel padding={`0 ${SPACING.MD}px`}>
                            <DateRangePickerDateTimeFieldContainer>
                                <DateTimeField
                                    label='From Date'
                                    variant='dashboard'
                                    value={fromDate}
                                    isEditable
                                    onValueChanged={(value) => {
                                        setActiveDateRange(null);
                                        setFromDate(value ? new Date(value) : undefined);
                                    }}
                                />
                                <DateTimeField
                                    label='To Date'
                                    variant='dashboard'
                                    value={toDate}
                                    isEditable
                                    onValueChanged={(value) => {
                                        setActiveDateRange(null);
                                        setToDate(value ? new Date(value) : undefined);
                                    }}
                                />
                            </DateRangePickerDateTimeFieldContainer>
                        </StackPanel>
                        {isFromDateLaterThanToDate && (
                            <Text variant='errorBold' textAlignment='center' padding={`0 ${SPACING.MD}px`}>
                                From date cannot be later than to date
                            </Text>
                        )}
                        <DateRangePickerDropdownFooter colors={colors}>
                            <GhostButton
                                id='date-range-picker'
                                onClick={() => {
                                    setIsOpen(false);
                                    setDashboardDateRange(dispatch, fromDate, toDate, activeDateRange);
                                    onRefresh();
                                }}
                                isEnabled={!isFromDateLaterThanToDate}>
                                Load Dashboard
                            </GhostButton>
                        </DateRangePickerDropdownFooter>
                    </Grid>
                </DateRangePickerDropdown>
            )}
        </StackPanel>
    );
}

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${SPACING.SM}px ${SPACING.MD}px;
`;
const ToggleContainer = styled.div`
    display: grid;
    flex: 1;
    grid-auto-flow: column;
    grid-auto-columns: 1fr 1fr;
    padding: 0 ${SPACING.MD}px;
`;
