import React, { useCallback, useMemo } from 'react';

import { Link } from 'react-router-dom';
import { constants } from 'constants/env-config';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';

export default function useExternalLinks() {
    const {
        state: {
            sections: {
                FinanceInformation: { oracleNumber },
            },
        },
    } = useCreationViewContext();

    const getPeriodName = () => {
        const date = new Date();

        return date.toLocaleDateString('en-US', { month: 'short', year: '2-digit' }).replace(' ', '-');
    };

    const extendWithSigmaFilterParams = useCallback(
        (url = '') => {
            const urlWithFilterParams = [`${url}?`];

            if (oracleNumber) {
                urlWithFilterParams.push(`Legal-Entity=${oracleNumber}&`);
            }

            urlWithFilterParams.push(`Period-Name=${getPeriodName()}`);

            return urlWithFilterParams.join('');
        },
        [oracleNumber]
    );

    const trialAndIntercompanyBalanceDashboardLinks = useMemo(
        () => (
            <>
                Click to view{' '}
                <Link
                    to={{ pathname: extendWithSigmaFilterParams(constants.TRIAL_BALANCES_DASHBOARD_URL) }}
                    target='_blank'>
                    Trial Balances Dashboard
                </Link>
                {' and '}
                <Link
                    to={{ pathname: extendWithSigmaFilterParams(constants.INTERCOMPANY_BALANCES_DASHBOARD_URL) }}
                    target='_blank'>
                    Intercompany Balances Dashboard
                </Link>{' '}
                to see balances associated with this entity
            </>
        ),
        [extendWithSigmaFilterParams]
    );

    const trialBalanceDashboardLink = useMemo(
        () => (
            <>
                Click to view{' '}
                <Link
                    to={{ pathname: extendWithSigmaFilterParams(constants.TRIAL_BALANCES_DASHBOARD_URL) }}
                    target='_blank'>
                    Trial Balances Dashboard
                </Link>{' '}
                to see trial balances associated with this entity
            </>
        ),
        [extendWithSigmaFilterParams]
    );

    const interCompanyBalanceDashboardLink = useMemo(
        () => (
            <>
                Click to view{' '}
                <Link
                    to={{ pathname: extendWithSigmaFilterParams(constants.INTERCOMPANY_BALANCES_DASHBOARD_URL) }}
                    target='_blank'>
                    Intercompany Balances Dashboard
                </Link>{' '}
                to see intercompany balances associated with this entity
            </>
        ),
        [extendWithSigmaFilterParams]
    );

    const linkToWorkflowsTool = (
        <>
            <Link to={{ pathname: constants.WORKFLOWS_TOOL_URL }} target='_blank'>
                Link
            </Link>{' '}
            to the Workflows tool to ask Treasury to close bank accounts
        </>
    );

    return {
        trialAndIntercompanyBalanceDashboardLinks,
        trialBalanceDashboardLink,
        interCompanyBalanceDashboardLink,
        linkToWorkflowsTool,
    };
}
