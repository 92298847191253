import React from 'react';
import styled from 'styled-components';

type Props = {
    mailTo: string;
    label: string;
};

const EmailLink = ({ mailTo, label }: Props) => {
    return (
        <ButtonMail
            onClick={(e) => {
                window.location.href = `mailto:${mailTo}`;
            }}>
            {label}
        </ButtonMail>
    );
};

const ButtonMail = styled.button`
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    font-style: inherit;
    font-size: inherit;
`;

export default EmailLink;
