import {
    AdvancedFieldOptionItem,
    EntityMasterSearchRequestV4,
    FieldItem,
    IndexType,
    RequestedIndexSearch,
    RequestedSearchItem,
    SubfieldToSearch,
} from 'models/LegalEntitySearch/SearchRequest';

import { ElasticSearchResult } from 'models/LegalEntitySearch/ElasticSearchResult';
import { EntityStatusId } from 'models/LegalEntity/EntityStatus';
import { EntitySubType } from 'models/LegalEntity/EntitySubType';
import { EntityType } from 'models/LegalEntity/EntityType';
import { SearchResultItem } from './model';

export function createSearchRequest(
    search: RequestedSearchItem[],
    filter: RequestedIndexSearch['searchFilters'] = []
): EntityMasterSearchRequestV4<SearchResultItem> {
    const allFilters: typeof filter = [
        {
            field: 'entityStatusId',
            value: [EntityStatusId.Active],
        },
        {
            field: 'entityTypeId',
            value: [EntityType.Organization],
        },
        {
            field: 'dataOwnerId',
            value: [2],
        },
        {
            field: 'entitySubtypes',
            value: [EntitySubType.LegalEntity],
        },
        ...filter,
    ];

    const searchRequest: EntityMasterSearchRequestV4<SearchResultItem> = {
        indexType: IndexType.MdmEntity,
        indexSearchRequest: {
            searchItems: search,
            searchFilters: allFilters,
        },
        resultOptions: {
            numberOfResults: 100,
            fieldsToReturn: [
                'entityOId',
                'primaryName',
                'shortName',
                'businessUnits',
                'subBusinessUnits',
                'hqAddressLine1',
                'hqAddressLine2',
                'hqAddressLine3',
                'hqAddressLine4',
                'hqAddressCity',
                'hqAddressState',
                'hqAddressCountry',
                'hqAddressZipCode',
                'businessUnitIds',
                'subBusinessUnitIds',
            ],
            includeMetaData: false,
        },
    };

    return searchRequest;
}

export function getFieldItem(fieldName: string, subField?: SubfieldToSearch): FieldItem[] {
    let result: FieldItem[] = [
        {
            field: fieldName,
        },
    ];

    if (subField) {
        let advancedFieldOptionItem: AdvancedFieldOptionItem[] = [
            {
                subFieldToSearch: subField,
            },
        ];
        result[0].advancedFieldOptions = advancedFieldOptionItem;
    }

    return result;
}

export function getResultData(response: ElasticSearchResult<SearchResultItem>): SearchResultItem[] {
    return (
        response.searchResults
            .map((searchResult) => {
                const data = { ...searchResult.data };
                data.businessUnit = data.businessUnits.length ? data.businessUnits[0] : '';
                data.subBusinessUnit = data.subBusinessUnits.length ? data.subBusinessUnits[0] : '';

                return data;
            })
            .sort((a, b) => b.entityOId - a.entityOId) ?? []
    );
}

export function FillHqAddress(results: SearchResultItem[]): SearchResultItem[] {
    results.forEach((result) => {
        result.hqAddress =
            GetLine(result.hqAddressLine1) +
            GetLine(result.hqAddressLine2) +
            GetLine(result.hqAddressLine3) +
            GetLine(result.hqAddressLine4) +
            GetLine(result.hqAddressCity) +
            GetLine(result.hqAddressState) +
            GetLine(result.hqAddressCountry) +
            GetLine(result.hqAddressZipCode);
    });
    return results;
}

function GetLine(line: string): string {
    return line ? ' ' + line : '';
}
