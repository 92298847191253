import { Button, StackPanel, TextBlock, ThemeManager } from '@bxgrandcentral/controls';
import { CellClickedEvent, GridApi, ModelUpdatedEvent } from 'ag-grid-community';
import { DoubleColumnLayout, SPACING } from 'Utilities/Layout';
import { Expander, Select, TextField } from 'components/';
import { FillHqAddress, createSearchRequest, getFieldItem, getResultData } from './utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    RequestedIndexSearch,
    RequestedSearchItem,
    SubfieldToSearch,
} from '../../../models/LegalEntitySearch/SearchRequest';
import { updateSearchFilters, useAppContext } from 'context/app-context';

import { AgGridReact } from 'ag-grid-react';
import { GridApiRef } from 'hooks/use-column-configuration';
import GridLoadingOverlay from 'modules/RequestsDashboard/components/Grid/components/GridLoadingOverlay';
import GridNoRowsOverlay from 'modules/RequestsDashboard/components/Grid/components/GridNoRowsOverlay';
import { IEMSearchService } from '../../../services/interfaces';
import { KeyValue } from '../../../models/LegalEntityRequest/ReferenceData';
import { LegalEntityRequest } from 'models';
import NewTabButton from '../Shared/NewTabButton';
import { SearchResultItem } from './model';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import useBusinessUnits from './hooks/use-business-units';
import useContextMenu from 'hooks/use-context-menu';
import useMatchEntitiesDashboardGridConfig from './hooks/use-match-entities-dashboard-grid-config';
import useNavigation from 'hooks/use-navigation';
import useProcessedSearchResults from './hooks/use-processed-search-results';

let abortControllers: AbortController[] = [];

export const MatchLegalEntitiesView = () => {
    const legalEntitySearch = ServiceLocator.container.resolve(IEMSearchService);

    const {
        state: { search: searchState },
        dispatch,
    } = useAppContext();

    const { businessUnitOId, subBusinessUnitOId, legalName, legalShortName, entityOid } = searchState;

    const { allowedBusinessUnits, allSubBusinessUnits } = useBusinessUnits({ abortControllers });

    const [allowedSubBusinessUnits, setAllowedSubBusinessUnits] = useState<KeyValue[]>([]);
    const [searchResults, setSearchResults] = useState<SearchResultItem[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    const [numberOfRows, setNumberOfRows] = useState(searchResults?.length || 0);
    const [isThereActiveFilter, setIsThereActiveFilter] = useState(false);
    const [gridApi, setGridApi] = useState<GridApi>();

    const gridRef = useRef<AgGridReact>(null);

    const { dashboardNewTabLink } = useNavigation();
    const { contextMenuPosition, handleCellContextMenu, closeContextMenu, ctrlKeyEvent } = useContextMenu({ gridRef });

    const apiRef = React.useRef({
        grid: undefined,
        column: undefined,
        api: undefined,
    } as GridApiRef);

    const onGridReady = (params: any) => {
        setGridApi(params.api);
        apiRef.current.column = params.columnApi;
        apiRef.current.api = params.api;
        params.api.sizeColumnsToFit();
    };

    const handleFilterChange = () => {
        setIsThereActiveFilter(!isEmpty(gridApi?.getFilterModel()));
    };

    const clearFilters = () => {
        if (gridApi) {
            gridApi.setFilterModel(null);
            gridApi.onFilterChanged();
        }
    };

    function SetBUFilters(filter: RequestedIndexSearch['searchFilters'] | undefined) {
        let directBus: number[] = [];
        let directSubus: number[] = [];

        allowedBusinessUnits?.BusinessUnit?.forEach((bu) => {
            if (bu.IsDirect) {
                directBus.push(bu.Key);
            } else {
                const subBusinessUnits =
                    allSubBusinessUnits?.SubBusinessUnit?.filter((item) => Number(item.ParentId) === bu.Key).map(
                        (bu) => bu.Key
                    ) || [];
                if (subBusinessUnits && subBusinessUnits.length > 0) {
                    subBusinessUnits.forEach((sbu) => {
                        directSubus.push(sbu);
                    });
                }
            }
        });

        if (filter && directBus.length > 0) {
            filter.push({
                field: 'businessUnitIds',
                isOr: true,
                value: directBus,
            });
        }

        if (filter && directSubus.length > 0) {
            filter.push({
                field: 'subBusinessUnitIds',
                isOr: true,
                value: directSubus,
            });
        }
    }

    // On business unit change we have to update the allowed sub business unit options
    useEffect(() => {
        let filteredSubBusinessUnits: KeyValue[] = [];

        if (businessUnitOId) {
            filteredSubBusinessUnits =
                allSubBusinessUnits?.SubBusinessUnit?.filter((item) => Number(item.ParentId) === businessUnitOId) || [];
        }

        // we need to reset to sub business unit selector if it has a value
        if (subBusinessUnitOId) {
            updateSearchFilters(dispatch, {
                ...searchState,
                subBusinessUnitOId: undefined,
            });
        }

        setAllowedSubBusinessUnits(filteredSubBusinessUnits);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessUnitOId]);

    useEffect(() => {
        if (!gridRef.current?.api) {
            return;
        }

        const search: RequestedSearchItem[] = [];
        const filter: RequestedIndexSearch['searchFilters'] = [];

        if (legalName) {
            search.push({
                fields: getFieldItem('primaryName'),
                queryString: legalName,
            });
        }

        if (legalShortName) {
            search.push({
                fields: getFieldItem('shortName'),
                queryString: legalShortName,
            });
        }

        if (entityOid) {
            search.push({
                fields: getFieldItem('entityOId', SubfieldToSearch.SearchDefaultField),
                queryString: entityOid.toString(),
            });
        }

        if (businessUnitOId) {
            filter.push({
                field: 'businessUnitIds',
                value: [businessUnitOId],
            });

            if (subBusinessUnitOId) {
                filter.push({
                    field: 'subBusinessUnitIds',
                    value: [subBusinessUnitOId],
                });
            } else {
                const filteredSubBusinessUnits =
                    allSubBusinessUnits?.SubBusinessUnit?.filter((item) => Number(item.ParentId) === businessUnitOId) ||
                    [];
                const subBusinessUnitFilter = filteredSubBusinessUnits?.map((bu) => bu.Key);
                const iDirect = allowedBusinessUnits?.BusinessUnit?.find((bu) => bu.Key === businessUnitOId)?.IsDirect;
                if (subBusinessUnitFilter?.length > 0 && !iDirect) {
                    filter.push({
                        field: 'subBusinessUnitIds',
                        value: subBusinessUnitFilter,
                    });
                }
            }
        } else {
            const businessUnitFilter = allowedBusinessUnits?.BusinessUnit?.map((bu) => bu.Key);
            if (businessUnitFilter && businessUnitFilter.length > 0) {
                SetBUFilters(filter);
            }
        }

        if (search.length) {
            setIsError(false);
            const abortController = new AbortController();

            if (abortControllers.length) {
                abortControllers[abortControllers.length - 1].abort();
            }

            gridRef.current?.api?.showLoadingOverlay();

            const request = createSearchRequest(search, filter);
            abortControllers.push(abortController);

            legalEntitySearch
                .searchLegalEntity(request, abortController)
                .then((result) => {
                    gridRef.current?.api?.hideOverlay();

                    const searchResults: SearchResultItem[] = getResultData(result);
                    if (!searchResults.length) {
                        gridRef.current?.api?.showNoRowsOverlay();
                    }
                    setSearchResults(FillHqAddress(searchResults));
                })
                .catch((e: ErrorEvent) => {
                    if (!e?.message?.includes('The user aborted a request.')) {
                        setIsError(true);
                    }
                    gridRef.current?.api?.showNoRowsOverlay();
                    setSearchResults([]);
                });
        } else {
            setSearchResults([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessUnitOId, entityOid, legalName, legalShortName, subBusinessUnitOId, gridRef.current?.api]);

    const resizeColumns = () => {
        (apiRef.current?.api as any).sizeColumnsToFit();
    };

    const onModelUpdated = useCallback(
        ({ api }: ModelUpdatedEvent) => {
            const rowCount = api.getModel().getRowCount();
            if (numberOfRows !== rowCount) {
                setNumberOfRows(rowCount);
            }
        },
        [numberOfRows]
    );

    const noRowsMessage =
        businessUnitOId ||
        entityOid ||
        legalName?.length ||
        legalShortName?.length ||
        subBusinessUnitOId ||
        (apiRef.current?.api as any)?.isAnyFilterPresent()
            ? 'There are no matching entities.'
            : '';

    const theme = ThemeManager.activeTheme.name === 'Dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';

    const { processedSearchResults, legalEntityStatuses } = useProcessedSearchResults({ searchResults });

    const { matchLegalEntitiesColumnDefs } = useMatchEntitiesDashboardGridConfig({
        legalEntityStatuses,
        data: searchResults,
        gridApi: apiRef?.current,
    });

    useEffect(() => {
        gridApi?.setRowData(processedSearchResults);
        clearFilters();
    }, [processedSearchResults]);

    return (
        <Layout>
            <Expander
                header='Legal Entity Information'
                content={
                    <DoubleColumnLayout>
                        <StackPanel itemGap={SPACING.MD}>
                            <TextField
                                label='Entity ID'
                                canUndo={false}
                                value={entityOid?.toString()}
                                onValueChanged={(newValue) => {
                                    updateSearchFilters(dispatch, {
                                        ...searchState,
                                        entityOid: newValue,
                                    });
                                }}
                                isEditable
                            />
                            <Select
                                label='Business Unit'
                                itemsSource={allowedBusinessUnits?.BusinessUnit || []}
                                value={businessUnitOId}
                                isEditable
                                onValueChanged={(newValue) => {
                                    updateSearchFilters(dispatch, {
                                        ...searchState,
                                        businessUnitOId: newValue,
                                    });
                                }}
                            />
                            {!!allowedSubBusinessUnits.length && (
                                <Select
                                    label='Sub-Business Unit'
                                    itemsSource={allowedSubBusinessUnits}
                                    value={subBusinessUnitOId}
                                    onValueChanged={(newValue) => {
                                        updateSearchFilters(dispatch, {
                                            ...searchState,
                                            subBusinessUnitOId: newValue,
                                        });
                                    }}
                                    isEditable
                                />
                            )}
                        </StackPanel>
                        <StackPanel itemGap={SPACING.MD}>
                            <TextField
                                label='Legal Entity Name'
                                canUndo={false}
                                value={legalName}
                                onValueChanged={(newValue) => {
                                    updateSearchFilters(dispatch, {
                                        ...searchState,
                                        legalName: newValue,
                                    });
                                }}
                                isEditable
                            />
                            <TextField
                                label='Legal Entity Short Name'
                                canUndo={false}
                                value={legalShortName}
                                onValueChanged={(newValue) => {
                                    updateSearchFilters(dispatch, {
                                        ...searchState,
                                        legalShortName: newValue,
                                    });
                                }}
                                isEditable
                            />
                        </StackPanel>
                    </DoubleColumnLayout>
                }
            />
            <StackPanel styleOverrides={{ position: 'relative' }}>
                <TextBlock
                    text='Matching Entities'
                    styleName='heading1Style'
                    margin={`${SPACING.XL}px 0 ${SPACING.MD}px 0`}
                />
                {isThereActiveFilter && (
                    <ClearFilterButtonContainer>
                        <Button content='Clear Table Filters' onClick={clearFilters} />
                    </ClearFilterButtonContainer>
                )}
            </StackPanel>
            <div className={theme}>
                <AgGridReact
                    ref={gridRef}
                    onCellClicked={({ data, event }: CellClickedEvent<LegalEntityRequest>) => {
                        ctrlKeyEvent(event, data);
                    }}
                    columnDefs={matchLegalEntitiesColumnDefs}
                    onGridReady={onGridReady}
                    onFirstDataRendered={resizeColumns}
                    loadingOverlayComponent={GridLoadingOverlay}
                    onModelUpdated={onModelUpdated}
                    rowClass='custom-row'
                    noRowsOverlayComponent={GridNoRowsOverlay}
                    noRowsOverlayComponentParams={{ noRowsMessage, isError }}
                    onCellContextMenu={handleCellContextMenu}
                    suppressAsyncEvents={true}
                    alwaysMultiSort={false}
                    onFilterChanged={handleFilterChange}
                />
                {contextMenuPosition?.popUpVisible && (
                    <NewTabButton
                        closeContextMenu={closeContextMenu}
                        newTabLink={dashboardNewTabLink}
                        contextMenu={contextMenuPosition}
                    />
                )}
            </div>
        </Layout>
    );
};

const Layout = styled.div`
    display: grid;
    grid-template-rows: auto auto 1fr;
    padding: ${SPACING.XL}px;
`;

const ClearFilterButtonContainer = styled.div`
    position: absolute;
    right: 0px;
    top: 28px;
`;
