import { useEffect, useState } from 'react';

import { ILegalEntityService } from '../../../../services/interfaces';
import { ReferenceData } from '../../../../models';
import { ServiceLocator } from '@bxgrandcentral/shell';

type Props = {
    abortControllers: any[];
};

export default function useBusinessUnits({ abortControllers }: Props) {
    const legalEntityRequest = ServiceLocator.container.resolve(ILegalEntityService);

    const [allowedBusinessUnits, setAllowedBusinessUnits] = useState<Pick<ReferenceData, 'BusinessUnit'>>();
    const [allSubBusinessUnits, setAllSubBusinessUnits] = useState<Pick<ReferenceData, 'SubBusinessUnit'>>();

    useEffect(() => {
        legalEntityRequest.GetUserBusinessUnits().then((response) => {
            setAllowedBusinessUnits(response);
        });
        return () => {
            abortControllers = [];
        };
    }, [legalEntityRequest]);

    useEffect(() => {
        legalEntityRequest.GetUserSubBusinessUnits().then((response) => {
            setAllSubBusinessUnits(response);
        });
    }, [legalEntityRequest]);

    return { allowedBusinessUnits, allSubBusinessUnits };
}
