import { Button, FontIcon, StackPanel } from '@bxgrandcentral/controls';

import { GlobalState } from 'GlobalState';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { removeAt } from 'Utilities/array';
import { useLegalEntityOwnerShipInformation } from 'modules/LegalEntityCreation/context/Provider';

type Props = {
    clearRowSelection: () => void;
} & ICellRendererParams;

export default function GridDeleteButton(props: Props) {
    const {
        data: { isDeleted, index },
        clearRowSelection,
    } = props;

    const {
        state: {
            data: {
                values: { ownershipDetails = [] },
            },
            isEditable,
        },
        setValue,
    } = useLegalEntityOwnerShipInformation();

    return (
        <Button
            content={
                <StackPanel orientation='horizontal' verticalAlignment='center' itemGap={SPACING.XXS}>
                    <FontIcon iconName={isDeleted ? 'Undo' : 'Delete'} />
                </StackPanel>
            }
            styleOverrides={{ alignSelf: 'center' }}
            styleName='textOnlyButtonStyle'
            padding={`${SPACING.XS}px ${SPACING.MD}px`}
            isEnabled={isEditable}
            onClick={async () => {
                const updated = [...ownershipDetails];
                const itemIndex = ownershipDetails.findIndex((item) => item.index === index);

                if (!isDeleted && !updated[itemIndex].key) {
                    await GlobalState.openDialog({
                        variant: 'info',
                        title: 'Delete Ownership Details',
                        content:
                            'Are you sure you want to remove these Ownership Details?  Any information on this unsaved relationship will be lost.',
                    }).then((response) => {
                        if (response) {
                            const cleaned = removeAt(updated, itemIndex);
                            setValue('ownershipDetails', cleaned);
                            clearRowSelection();
                        }
                    });
                } else {
                    updated[itemIndex].isDeleted = !isDeleted;
                    setValue('ownershipDetails', updated);
                }
            }}
        />
    );
}
