import { Button, Container, Wrapper } from '../components.styled';
import { FontIcon, StackPanel } from '@bxgrandcentral/controls';
import { GridApiRef, dateFormatters, dateTimeFormatters, fieldsWithRawValues } from 'hooks/use-column-configuration';
import React, { useRef, useState } from 'react';
import { dateFormatter, dateTimeFormatter, getFriendlyValues } from '../Grid/model';

import { ColDef } from 'ag-grid-community';
import { DASHBOARD_VIEW_STATE } from 'modules/RequestsDashboard/models';
import { LegalEntityRequest } from 'models';
import { fetchLegalEntities } from 'api/hooks/use-legal-entities';
import { isNil } from 'lodash';
import { preProcessFields } from 'modules/RequestsDashboard/utils';
import { useAppContext } from 'context/app-context';
import useColors from 'api/hooks/use-theme';
import useExport from './hooks/use-export';
import useOnClickOutside from 'hooks/use-click-outside';

type Props = {
    apiRef: GridApiRef;
    filteredData?: LegalEntityRequest[];
    isEnabled: boolean;
};

export default function Export({ apiRef, filteredData = [], isEnabled }: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const colors = useColors();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);
    const { save } = useExport();
    const {
        state: {
            settings: { userTraksCache },
        },
    } = useAppContext();

    useOnClickOutside({ ref: [wrapperRef, buttonRef], onClickOutside: () => setIsOpen(false) });

    function getFormattedValues({
        entityKey,
        columnDef,
        row,
    }: {
        entityKey: keyof LegalEntityRequest;
        columnDef: Partial<ColDef>;
        row: Partial<LegalEntityRequest>;
    }) {
        const valueKey = columnDef.headerName as string;
        return {
            ...(fieldsWithRawValues.includes(entityKey) && {
                [valueKey]: getFriendlyValues({
                    value: row[entityKey] as string,
                }),
            }),
            ...(dateFormatters.includes(entityKey) && {
                [valueKey]: dateFormatter({
                    value: row[entityKey] as string,
                }),
            }),
            ...(dateTimeFormatters.includes(entityKey) && {
                [valueKey]: dateTimeFormatter({
                    value: row[entityKey] as string,
                }),
            }),
        };
    }

    function getColumnDefs({ shouldSelectAll = false } = {}) {
        const visibleColumns = apiRef?.api?.getColumnDefs()?.reduce((acc: Partial<ColDef>[], curr: ColDef) => {
            if (shouldSelectAll || !curr.hide) {
                acc.push({ field: curr.field, headerName: curr.headerName });
            }
            return acc;
        }, []);
        return visibleColumns;
    }

    function exportFilteredData() {
        const columnDefs = getColumnDefs() as Partial<ColDef>[];
        const data: LegalEntityRequest[] = [];
        apiRef?.api?.forEachNodeAfterFilterAndSort((row) => data.push(row.data));
        exportData(data, columnDefs);
    }

    async function exportAllData() {
        const allEntities = await fetchLegalEntities({
            userTraksCache,
            workItemState: DASHBOARD_VIEW_STATE[1],
        });
        exportData(preProcessFields(allEntities), getColumnDefs({ shouldSelectAll: true }) as Partial<ColDef>[]);
    }

    function exportData(data: LegalEntityRequest[], columnDef: Partial<ColDef>[]) {
        const exportData = data.map((row: Partial<LegalEntityRequest>) =>
            Object.keys(row).reduce<Partial<LegalEntityRequest>>((result, key) => {
                const entityKey = key as keyof LegalEntityRequest;
                const currentColumn = columnDef.find((column) => column.field === entityKey);
                return !isNil(currentColumn)
                    ? {
                          ...result,
                          [`${currentColumn.headerName}`]: row[entityKey] ?? '',
                          ...getFormattedValues({ entityKey, columnDef: currentColumn, row }),
                      }
                    : result;
            }, {})
        );
        save({ data: exportData, header: columnDef.map((column) => `${column.headerName}`) });
        setIsOpen(false);
    }
    return (
        <>
            <Button
                colors={colors}
                onClick={() => isEnabled && setIsOpen(!isOpen)}
                ref={buttonRef}
                {...(!isEnabled && { className: 'disabled' })}>
                <FontIcon
                    iconName='ExcelDocument'
                    foregroundColor={colors.foregroundColor}
                    fontSize={20}
                    toolTip={'Export data results into an Excel file'}
                />
            </Button>
            <Wrapper colors={colors} isOpen={isOpen} ref={wrapperRef}>
                <StackPanel styleOverrides={{ overflowY: 'auto', maxHeight: '350px', width: '100%' }}>
                    <Container colors={colors} onClick={() => exportAllData()}>
                        Export all data
                    </Container>
                    <Container colors={colors} onClick={() => exportFilteredData()}>
                        Export filtered data
                    </Container>
                </StackPanel>
            </Wrapper>
        </>
    );
}
