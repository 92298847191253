import { Button, StackPanel } from '@bxgrandcentral/controls';
import React, { useState } from 'react';
import { getFormattedSortedUserScopes, getWorkitemsFromState } from './utils';

import { AppState } from 'context/model';
import { Content } from './Debugger.styled';
import { CreationViewState } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/model';
import { SPACING } from 'Utilities/Layout';
import { isNil } from 'lodash';
import useColors from 'api/hooks/use-theme';

type Props = {
    inputState: any;
    state: AppState;
    creationViewState: CreationViewState;
    showUndefinedValues: boolean;
};

export default function StatePanel({ inputState, state, creationViewState, showUndefinedValues = true }: Props) {
    const colors = useColors();
    const [isStateOpen, setIsStateOpen] = useState(false);
    const [isUserScopesStateOpen, setIsUserScopesStateOpe] = useState(false);
    const [isWorkitemStatesOpen, setIsWorkitemStatesOpen] = useState(false);
    const [isAppStateOpen, setIsAppStateOpen] = useState(false);
    const [isCreationViewStateOpen, setIsCreationViewStateOpen] = useState(false);

    return (
        <Content colors={colors}>
            <>
                {!isNil(inputState) && (
                    <>
                        <Button onClick={() => setIsStateOpen(!isStateOpen)}>
                            {`${isStateOpen ? 'Hide' : 'Show'} Form State`}
                        </Button>
                        {isStateOpen && (
                            <StackPanel
                                styleOverrides={{
                                    overflow: 'scroll',
                                    height: '800px',
                                }}>
                                <p>
                                    {JSON.stringify(
                                        inputState,
                                        (_, value) =>
                                            showUndefinedValues && value === undefined ? 'undefined' : value,
                                        4
                                    )}
                                </p>
                            </StackPanel>
                        )}
                    </>
                )}
            </>
            <StackPanel
                styleOverrides={{
                    marginTop: `${SPACING.MD}px`,
                }}>
                <Button onClick={() => setIsWorkitemStatesOpen(!isWorkitemStatesOpen)}>
                    {`${isWorkitemStatesOpen ? 'Hide' : 'Show'} Workitem States`}
                </Button>
                {isWorkitemStatesOpen && (
                    <StackPanel
                        styleOverrides={{
                            overflow: 'scroll',
                            height: '600px',
                        }}>
                        <p>
                            {JSON.stringify(
                                getWorkitemsFromState(creationViewState),
                                (_, value) => (showUndefinedValues && value === undefined ? 'undefined' : value),
                                4
                            )}
                        </p>
                    </StackPanel>
                )}
            </StackPanel>
            <StackPanel
                styleOverrides={{
                    marginTop: `${SPACING.MD}px`,
                }}>
                <Button onClick={() => setIsUserScopesStateOpe(!isUserScopesStateOpen)}>
                    {`${isUserScopesStateOpen ? 'Hide' : 'Show'} User Scopes`}
                </Button>
                {isUserScopesStateOpen && (
                    <StackPanel
                        styleOverrides={{
                            overflow: 'scroll',
                            height: '600px',
                        }}>
                        <ul>
                            {getFormattedSortedUserScopes(creationViewState.userScopes).map((scope) => (
                                <li>{scope}</li>
                            ))}
                        </ul>
                    </StackPanel>
                )}
            </StackPanel>
            <StackPanel
                styleOverrides={{
                    marginTop: `${SPACING.MD}px`,
                }}>
                <Button onClick={() => setIsAppStateOpen(!isAppStateOpen)}>
                    {`${isAppStateOpen ? 'Hide' : 'Show'} App State`}
                </Button>
                {isAppStateOpen && (
                    <p>
                        {JSON.stringify(
                            state,
                            (_, value) => (showUndefinedValues && value === undefined ? 'undefined' : value),
                            4
                        )}
                    </p>
                )}
            </StackPanel>
            <StackPanel
                styleOverrides={{
                    marginTop: `${SPACING.MD}px`,
                }}>
                <Button onClick={() => setIsCreationViewStateOpen(!isCreationViewStateOpen)}>
                    {`${isCreationViewStateOpen ? 'Hide' : 'Show'} Creation View State`}
                </Button>
                {isCreationViewStateOpen && (
                    <p>
                        {JSON.stringify(
                            {
                                editWorkFlow: creationViewState.editWorkFlow,
                                isWorkItemsRefreshing: creationViewState.isWorkItemsRefreshing,
                                sideBarLength: creationViewState.sideBarLength,
                                loadingStates: creationViewState.loadingStates,
                                latestRejectedDissolutionWorkflow: creationViewState.latestRejectedDissolutionWorkflow,
                            },
                            (_, value) => (showUndefinedValues && value === undefined ? 'undefined' : value),
                            4
                        )}
                    </p>
                )}
            </StackPanel>
        </Content>
    );
}
