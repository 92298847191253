import { BooleanField, StackPanel } from '@bxgrandcentral/controls';
import { NavigationButton, Text } from 'components';
import {
    setIsAutoPopulationEnabled,
    setIsDebuggerToolsEnabled,
    setIsDocumentServiceIgnored,
    setIsQueryPanelEnabled,
    useAppContext,
} from '../../../context/app-context';

import React from 'react';
import { SPACING } from '../../../Utilities/Layout';
import { isLowerEnvironment } from '../../../Utilities/Environment';

export default function DeveloperSettingsEditor() {
    const {
        state: {
            settings: {
                isDocumentServiceIgnored,
                isAutoPopulationEnabled,
                isQueryPanelEnabled,
                isDebuggerToolsEnabled,
            },
        },
        dispatch,
    } = useAppContext();

    const lowerEnvironment = isLowerEnvironment();

    return (
        <StackPanel itemGap={SPACING.XL} padding={`${SPACING.XL}px ${SPACING.LG}px`}>
            <StackPanel>
                <Text variant='body'>Developer Settings</Text>
            </StackPanel>
            {lowerEnvironment && (
                <StackPanel itemGap={SPACING.LG}>
                    <BooleanField
                        label='Ignore Document Service'
                        value={isDocumentServiceIgnored}
                        canResetValue={false}
                        canUndo={false}
                        onValueChanged={(newValue?: boolean) => {
                            setIsDocumentServiceIgnored(dispatch, newValue);
                            localStorage.setItem('settingsIgnoreDocumentService', newValue!.toString());
                        }}
                    />
                    <BooleanField
                        label='Enable auto population'
                        canResetValue={false}
                        canUndo={false}
                        value={isAutoPopulationEnabled}
                        onValueChanged={(newValue = false) => {
                            setIsAutoPopulationEnabled(dispatch, newValue);
                            localStorage.setItem('settingsIsAutoPopulationEnabled', newValue.toString());
                        }}
                    />
                    <BooleanField
                        label='Enable Query Panel'
                        canResetValue={false}
                        canUndo={false}
                        value={isQueryPanelEnabled}
                        onValueChanged={(newValue = false) => {
                            setIsQueryPanelEnabled(dispatch, newValue);
                            localStorage.setItem('settingsIsQueryPanelEnabled', newValue.toString());
                        }}
                    />
                    <BooleanField
                        label='Enable Debugger Tools'
                        canResetValue={false}
                        canUndo={false}
                        value={isDebuggerToolsEnabled}
                        onValueChanged={(newValue = false) => {
                            setIsDebuggerToolsEnabled(dispatch, newValue);
                            localStorage.setItem('settingsIsDebuggerToolsEnabled', newValue.toString());
                        }}
                    />
                    <NavigationButton id='environment-variables' text='Environment variables' />
                </StackPanel>
            )}
        </StackPanel>
    );
}
