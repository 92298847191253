import { AppContext as AppContextModel, AppState, ForceEditProp, ProviderProps } from './model';
import { DateRangeLabels, dateRanges } from 'modules/RequestsDashboard/components/DateRangePicker/components/model';
import React, { createContext, useContext, useReducer } from 'react';
import { isNil, noop } from 'lodash';

import { DashboardViewOptions } from 'modules/RequestsDashboard/models';
import { constants } from 'constants/env-config';
import { subtractDays } from 'Utilities/date';
import useBeforeUnloadListener from 'hooks/use-before-unload-listener';

const initialState: AppState = {
    settings: {
        isDocumentServiceIgnored: localStorage.getItem('settingsIgnoreDocumentService') === 'true',
        isRequestConfirmationRequired: isNil(localStorage.getItem('settingsIsRequestConfirmationRequired'))
            ? true
            : localStorage.getItem('settingsIsRequestConfirmationRequired') === 'true',
        isAutoPopulationEnabled: isNil(localStorage.getItem('settingsIsAutoPopulationEnabled'))
            ? false
            : localStorage.getItem('settingsIsAutoPopulationEnabled') === 'true',
        isNavigationBarVisible: true,
        isQueryPanelEnabled: localStorage.getItem('settingsIsQueryPanelEnabled') === 'true',
        isDebuggerToolsEnabled: localStorage.getItem('settingsIsDebuggerToolsEnabled') === 'true',
        userTraksCache: isNil(localStorage.getItem('settingsUseTraksCache'))
            ? false
            : localStorage.getItem('settingsUseTraksCache') === 'true',
    },
    dashboard: {
        collapsedEntities: [],
        searchCriteria: '',
        groupByParentEntities: true,
        fromDate: subtractDays(new Date(), parseInt(constants.DEFAULT_NDAYS_DASHBOARD)),
        toDate: undefined,
        activeDateRange: dateRanges.LAST_N_DAYS,
        dashboardView: DashboardViewOptions[1].Key,
        isCtaReview: undefined,
    },
    legalEntityCreation: {
        resetForm: false,
        canSave: false,
        forceEditEntity: undefined,
        maxRefresh: 2,
    },
    search: {
        entityOid: undefined,
        businessUnitOId: undefined,
        subBusinessUnitOId: undefined,
        legalName: '',
        legalShortName: '',
    },
};

const AppContext = createContext<AppContextModel>({
    state: {} as AppState,
    dispatch: () => noop,
});
AppContext.displayName = 'AppContext';

function reducer(state: AppState, action: any) {
    switch (action.type) {
        case 'SET_IS_DOCUMENT_SERVICE_IGNORED': {
            return {
                ...state,
                settings: { ...state.settings, isDocumentServiceIgnored: action.isDocumentServiceIgnored },
            };
        }
        case 'SET_IS_REQUEST_CONFIRMATION_REQUIRED': {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    isRequestConfirmationRequired: action.value,
                },
            };
        }
        case 'SET_USE_TRAKS_CACHE': {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    userTraksCache: action.value,
                },
            };
        }
        case 'SET_RESET_LEGAL_ENTITY_FORM': {
            return {
                ...state,
                legalEntityCreation: {
                    ...state.legalEntityCreation,
                    resetForm: action.value,
                },
            };
        }
        case 'SET_IS_AUTO_POPULATION_ENABLED': {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    isAutoPopulationEnabled: action.value,
                },
            };
        }
        case 'SET_CAN_SAVE_LEGAL_ENTITY_CREATION_FORM': {
            return {
                ...state,
                legalEntityCreation: {
                    ...state.legalEntityCreation,
                    canSave: action.value,
                },
            };
        }
        case 'SET_FORCE_EDITED_ENTITY': {
            return {
                ...state,
                legalEntityCreation: {
                    ...state.legalEntityCreation,
                    forceEditEntity: action.value,
                },
            };
        }
        case 'UPDATE_DASHBOARD_FILTERS': {
            const {
                value: { collapsedEntities, searchCriteria, groupByParentEntities, dashboardView, isCtaReview },
            } = action;
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    collapsedEntities,
                    searchCriteria,
                    groupByParentEntities,
                    dashboardView,
                    isCtaReview,
                },
            };
        }
        case 'TOGGLE_NAVIGATION_BAR': {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    isNavigationBarVisible: !state.settings.isNavigationBarVisible,
                },
            };
        }
        case 'SET_IS_QUERY_PANEL_ENABLED': {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    isQueryPanelEnabled: action.value,
                },
            };
        }
        case 'SET_IS_ENVIRONMENT_VIEW_ENABLED': {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    isEnvironmentViewEnabled: action.value,
                },
            };
        }
        case 'SET_IS_DEBUGGER_TOOLS_ENABLED': {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    isDebuggerToolsEnabled: action.value,
                },
            };
        }
        case 'SET_DASHBOARD_DATE_RANGE': {
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    fromDate: action.fromDate,
                    toDate: action.toDate,
                    activeDateRange: action.activeDateRange,
                },
            };
        }

        case 'UPDATE_SEARCH_FILTERS': {
            return {
                ...state,
                search: {
                    ...state.search,
                    ...action.value,
                },
            };
        }

        default: {
            return state;
        }
    }
}

export function AppContextProvider({ children }: ProviderProps) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext() {
    const context = useContext(AppContext);
    if (isNil(context)) {
        throw new Error('useAppContext must be used within an AppContextProvider');
    }

    useBeforeUnloadListener(context.state.legalEntityCreation.canSave);

    return context;
}

export const setIsDocumentServiceIgnored = (dispatch: any, isDocumentServiceIgnored: any) =>
    dispatch({ type: 'SET_IS_DOCUMENT_SERVICE_IGNORED', isDocumentServiceIgnored });

export const setResetLegalEntityCreationForm = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_RESET_LEGAL_ENTITY_FORM', value });

export const setIsRequestConfirmationRequired = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_IS_REQUEST_CONFIRMATION_REQUIRED', value });

export const setUseTraksCache = (dispatch: any, value: any) => dispatch({ type: 'SET_USE_TRAKS_CACHE', value });

export const setCanSaveLegalEntityCreationForm = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_CAN_SAVE_LEGAL_ENTITY_CREATION_FORM', value });

export const updateDashboardFilters = (dispatch: any, value: any) =>
    dispatch({ type: 'UPDATE_DASHBOARD_FILTERS', value });

export const setIsAutoPopulationEnabled = (dispatch: any, value: boolean) =>
    dispatch({ type: 'SET_IS_AUTO_POPULATION_ENABLED', value });

export const setIsQueryPanelEnabled = (dispatch: any, value: boolean) =>
    dispatch({ type: 'SET_IS_QUERY_PANEL_ENABLED', value });

export const setIsEnvironmentViewEnabled = (dispatch: any, value: boolean) =>
    dispatch({ type: 'SET_IS_ENVIRONMENT_VIEW_ENABLED', value });

export const setIsDebuggerToolsEnabled = (dispatch: any, value: boolean) =>
    dispatch({ type: 'SET_IS_DEBUGGER_TOOLS_ENABLED', value });

export const updateSearchFilters = (dispatch: any, value: any) => dispatch({ type: 'UPDATE_SEARCH_FILTERS', value });

export const setDashboardDateRange = (
    dispatch: any,
    fromDate?: Date,
    toDate?: Date,
    activeDateRange?: DateRangeLabels | null
) => dispatch({ type: 'SET_DASHBOARD_DATE_RANGE', fromDate, toDate, activeDateRange });

export const toggleNavigationBar = (dispatch: any) => dispatch({ type: 'TOGGLE_NAVIGATION_BAR' });

export const setForceEditedEntityId = (dispatch: any, value?: ForceEditProp) =>
    dispatch({ type: 'SET_FORCE_EDITED_ENTITY', value });
