import { ColDef, DateFilter, TextFilter } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';

import { AuthorizedPersonModel } from '../../model';
import { GridApiRef } from 'hooks/use-column-configuration';
import GridDeleteButton from './GridDeleteButton';
import GridPendingColumnRenderer from '../common/GridPendingColumnRenderer';
import SetFilter from 'modules/RequestsDashboard/components/SetFilter/SetFilter';
import { dateFieldComparator } from 'modules/RequestsDashboard/utils';
import { dateFormatter } from 'modules/RequestsDashboard/components/Grid/model';

type Props = {
    clearRowSelection: () => void;
    mode: string;
    isStandaloneWorkflow: boolean;
    data?: AuthorizedPersonModel[];
    gridApi?: GridApiRef;
};

const fieldsWithSetFilter = ['title'];

export default function useAuthorizePersonColumnDefs({
    clearRowSelection,
    mode,
    isStandaloneWorkflow,
    data,
    gridApi,
}: Props) {
    const generateSetFilter = useCallback((field?: keyof AuthorizedPersonModel, data?: AuthorizedPersonModel[]) => {
        if (!field || !data) {
            return { filter: false };
        }

        const values = Array.from(new Set(data.map((e) => e[field]))).sort();
        return values.length > 0
            ? {
                  filter: SetFilter,
                  filterParams: {
                      values,
                      maxHeight: '200px',
                  },
              }
            : {};
    }, []);

    const authorizedPersonColumnDefs: ColDef[] = [
        {
            field: 'hasPendingChange',
            headerName: 'Pending',
            cellClass: 'cell-pending',
            headerTooltip: 'Pending',
            hide: !(mode === 'Approval' && isStandaloneWorkflow),
            maxWidth: 90,
            cellRenderer: GridPendingColumnRenderer,
        },
        {
            field: 'relatedEntityName',
            headerName: 'Name',
            cellClass: 'cell-relatedEntityName',
            minWidth: 160,
            headerTooltip: 'Name',
            sortable: true,
            filter: TextFilter,
            filterParams: {
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'title',
            headerName: 'Title',
            cellClass: 'cell-title',
            headerTooltip: 'Title',
            sortable: true,
        },
        {
            field: 'appointmentDate',
            headerName: 'Appointment Date',
            cellClass: 'cell-appointmentDate',
            valueFormatter: dateFormatter,
            headerTooltip: 'Appointment Date',
            maxWidth: 200,
            sortable: true,
            filter: DateFilter,
            filterParams: {
                comparator: dateFieldComparator,
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'resignationDate',
            headerName: 'Resignation Date',
            cellClass: 'cell-resignationDate',
            valueFormatter: dateFormatter,
            headerTooltip: 'Resignation Date',
            minWidth: 180,
            sortable: true,
            filter: DateFilter,
            filterParams: {
                comparator: dateFieldComparator,
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'isDeleted',
            headerName: 'Delete',
            cellClass: 'cell-delete',
            pinned: 'right',
            maxWidth: 85,
            cellRenderer: GridDeleteButton,
            cellRendererParams: {
                clearRowSelection,
            },
            hide: !['Create', 'Edit'].includes(mode),
        },
    ];

    const columnDefs: ColDef<AuthorizedPersonModel>[] = useMemo(() => {
        const newColDefs = authorizedPersonColumnDefs.map((column: ColDef<AuthorizedPersonModel>) => {
            const { field = '' } = column;
            return {
                ...column,
                ...(fieldsWithSetFilter.includes(field) && {
                    ...generateSetFilter(field as keyof AuthorizedPersonModel, data),
                }),
            };
        });

        return newColDefs;
    }, [authorizedPersonColumnDefs, data, generateSetFilter, gridApi]);

    return { columnDefs };
}
