import { EntityHierarchy } from '..';
import { EntityHierarchyServiceProviderDetail } from './LegalEntityDTO';
import { RecursivePartial } from '../../Utilities/ReflectionUtil';

export enum LegalType {
    Admin = 'Admin',
    Advisor = 'Advisor',
    GP = 'GP',
    SBS = 'SBS',
    FundMain = 'Fund-Main',
    CoInvest = 'Co-Invest',
    CoInvestDiscretionary = 'Co-Invest Discretionary',
    AIV = 'AIV',
    Sleeve = 'Sleeve (Only Available when BU is BTO)',
    BlockerLeveraged = 'Blocker - Leveraged',
    BlockerUnleveraged = 'Blocker - Unleveraged',
    SPV = 'SPV',
    SPVAggregator = 'SPV - Aggregator',
    REIT = 'REIT',
}

export enum AdministratedBy {
    NewYork = 'New York',
    Europe = 'Europe (exclude Lux.)',
    Asia = 'Asia',
    Luxembourg = 'Luxembourg',
    GPA = 'GPA',
    ExternalAdmin = 'External Admin',
    PortfolioCompany = 'Portfolio Company',
}

export enum KeyValueData {
    RealState = 100001,
    PrivateEquity = 100000,
    ExternalAdmin = 6,
    PortfolioCompany = 7,
    HedgeFundSolutions = 100003,
    CreditGSO = 100002,
    GeneralPartnersAccounting = 999999,
    BTO = 7869009,
    Sleeve = 13,
    Corporate = 999998
}
class EntityHierarchyIdentifierImpl implements EntityHierarchyIdentifier {
    public readonly entityOIdField: keyof Pick<EntityHierarchy, 'childEntityOId' | 'parentEntityOId'>;
    public readonly entityHierarchyType: number;
    public readonly serviceProviderType?: number;

    constructor(
        entityHierarchyType: number,
        entityOIdField: keyof Pick<EntityHierarchy, 'childEntityOId' | 'parentEntityOId'>,
        serviceProviderType?: number
    ) {
        this.entityHierarchyType = entityHierarchyType;
        this.serviceProviderType = serviceProviderType;
        this.entityOIdField = entityOIdField;
        this.isOfType = this.isOfType.bind(this);
        this.isOfDetailType = this.isOfDetailType.bind(this);
        this.generateHierarchy = this.generateHierarchy.bind(this);
    }
    public isOfType(
        entityHierarchy: Pick<EntityHierarchy, 'entityHierarchyTypeId'> & RecursivePartial<EntityHierarchy>
    ) {
        return (
            entityHierarchy.entityHierarchyTypeId === this.entityHierarchyType &&
            (this.serviceProviderType === undefined ||
                (entityHierarchy.serviceProviderDetails?.some(this.isOfDetailType) ?? false))
        );
    }
    public isOfDetailType(detail: Pick<EntityHierarchyServiceProviderDetail, 'serviceProviderTypeId'>) {
        return detail.serviceProviderTypeId === this.serviceProviderType;
    }
    public generateHierarchy(
        from: RecursivePartial<EntityHierarchy>
    ): Pick<EntityHierarchy, 'entityHierarchyTypeId'> & RecursivePartial<EntityHierarchy> {
        const hierarchy = JSON.parse(JSON.stringify(from ?? {})) as ReturnType<
            EntityHierarchyIdentifierImpl['generateHierarchy']
        >;
        hierarchy.entityHierarchyTypeId = this.entityHierarchyType;
        if (this.serviceProviderType !== undefined && !hierarchy.serviceProviderDetails?.some(this.isOfDetailType)) {
            hierarchy.serviceProviderDetails = hierarchy.serviceProviderDetails ?? [];
            hierarchy.serviceProviderDetails = [{ serviceProviderTypeId: this.serviceProviderType }];
        }
        return hierarchy;
    }
}

export interface EntityHierarchyIdentifier {
    readonly entityHierarchyType: number;
    readonly serviceProviderType?: number;
    readonly entityOIdField: keyof Pick<EntityHierarchy, 'childEntityOId' | 'parentEntityOId'>;
    isOfType(
        entityHierarchy: Pick<EntityHierarchy, 'entityHierarchyTypeId'> & RecursivePartial<EntityHierarchy>
    ): boolean;
    isOfDetailType(detail: EntityHierarchyServiceProviderDetail): boolean;
    generateHierarchy(
        from: RecursivePartial<EntityHierarchy>
    ): Pick<EntityHierarchy, 'entityHierarchyTypeId'> & RecursivePartial<EntityHierarchy>;
}

export class EntityHierarchyTypes {
    public static readonly project = new EntityHierarchyIdentifierImpl(127, 'parentEntityOId');
    public static readonly externalAdmin = new EntityHierarchyIdentifierImpl(122, 'childEntityOId', 5);
    public static readonly administratorHKAPAC = new EntityHierarchyIdentifierImpl(122, 'childEntityOId', 13);
    public static readonly lawFirm = new EntityHierarchyIdentifierImpl(122, 'childEntityOId', 6);
    public static readonly portfolio = new EntityHierarchyIdentifierImpl(122, 'childEntityOId', 9);
    public static readonly taxAuditor = new EntityHierarchyIdentifierImpl(122, 'childEntityOId', 7);
    public static readonly taxPreparer = new EntityHierarchyIdentifierImpl(122, 'childEntityOId', 12);
    public static readonly signatory = new EntityHierarchyIdentifierImpl(114, 'childEntityOId');
    public static readonly legalOwnership = new EntityHierarchyIdentifierImpl(15, 'parentEntityOId');
    public static readonly associatedDeal = new EntityHierarchyIdentifierImpl(128, 'parentEntityOId');
}
