import {
    AdvancedFieldOptionItem,
    EntityMasterSearchRequestV4,
    FieldItem,
    IndexType,
    RequestedIndexSearch,
    RequestedSearchExecutionType,
    RequestedSearchItem,
    SearchOperatorType,
    SubfieldToSearch,
} from '../../../models/LegalEntitySearch/SearchRequest';
import { Grid, SelectorField, StackPanel, TextBlock, WaitingIndicator } from '@bxgrandcentral/controls';
import React, { useEffect, useState } from 'react';

import { ElasticSearchResult } from '../../../models/LegalEntitySearch/ElasticSearchResult';
import { EntityStatusId } from '../../../models/LegalEntity/EntityStatus';
import { EntitySubType } from '../../../models/LegalEntity/EntitySubType';
import { FormControl } from '../controls.styled';
import { IEMSearchService } from '../../../services/interfaces/IEMSearchService';
import { SPACING } from 'Utilities/Layout';
import { SearchEntity } from '../../../models/LegalEntitySearch/SearchEntity';
import { ServiceLocator } from '@bxgrandcentral/shell';
import useColors from 'api/hooks/use-theme';
import { isEmpty } from 'Utilities/Validations';
import { AlternateNamesTooltip } from 'modules/LegalEntityCreation/Shared/Tooltips';

interface ElasticSearchFieldProps {
    label: string;
    labelToolTip?: any;
    onEntitySelected: (entityData: Pick<SearchEntity, 'entityOId' | 'primaryName'> | undefined) => void;
    searchType: EntitySubType;
    selectedEntityOId?: number;
    restrictTo?: Partial<{ businessUnitIds: number[] }>;
    validationError?: string | Error;
    margin?: string;
    isEnabled?: boolean;
    isReadOnly?: boolean;
    canClearSelection?: boolean;
    maxHeight?: number;
    isRequired?: boolean;
    isPendingChange?: boolean;
}

type SearchResult = Pick<SearchEntity, 'entityOId' | 'primaryName'>;

export const getAlternateNames = (entity: SearchEntity) => {
    return entity.alternateNames?.filter((name) => entity.primaryName != name && !isEmpty(name)).join(', ');
};

let abortControllers: AbortController[] = [];
export default function ElasticSearchField(props: ElasticSearchFieldProps) {
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [selectedItem, setSelectedItem] = useState<SearchResult>();
    const [internalValue, setInternalValue] = useState<SearchResult>();
    const [isMounting, setIsMounting] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const legalEntitySearch = ServiceLocator.container.resolve(IEMSearchService);

    const { accentColor, foregroundColor } = useColors();

    const alternateNamesLengthLimit = 3;

    const itemTemplate = (entity: SearchEntity) => (
        <StackPanel>
            <TextBlock text={`${entity.primaryName}`} styleName='fieldValueStyle' />
            <TextBlock styleName='captionStyle' text={`(ID: ${entity.entityOId})`} />
        </StackPanel>
    );

    const thirdPartiesTemplate = (entity: SearchEntity) => {
        const alternateNames = getAlternateNames(entity);
        const exceededLimit = entity?.alternateNames && entity?.alternateNames?.length > alternateNamesLengthLimit;
        const limitedAlternateNames = exceededLimit
            ? `${entity?.alternateNames?.slice(0, alternateNamesLengthLimit)}...`
            : alternateNames;

        return (
            <StackPanel>
                <TextBlock text={`${entity.primaryName} (ID: ${entity.entityOId})`} styleName='fieldValueStyle' />
                {!isEmpty(entity.shortName) && entity.shortName !== entity.primaryName && (
                    <TextBlock styleName='captionStyle' text={`Short Name: ${entity.shortName}`} />
                )}
                {!isEmpty(alternateNames) && (
                    <>
                        <TextBlock styleName='captionStyle' text={`Alternate Names: ${limitedAlternateNames}`} />
                        {exceededLimit && (
                            <TextBlock
                                styleName='captionStyle'
                                fontWeight='bold'
                                foregroundColor={foregroundColor}
                                text='(Hover over this text to see all associated names)'
                                toolTip={AlternateNamesTooltip(entity)}
                            />
                        )}
                    </>
                )}
            </StackPanel>
        );
    };

    function GetResultData<T extends Partial<SearchEntity>>(response: ElasticSearchResult<T>): T[] {
        return response.searchResults.map((searchResult) => searchResult.data);
    }

    function GetFieldItem(fieldNames: string[]): FieldItem[] {
        let result: FieldItem[] = [];
        fieldNames.forEach((element) => {
            result.push({ field: element });
        });

        function GetAdvancedFieldOptions(fieldName: string): AdvancedFieldOptionItem[] {
            return [
                {
                    subFieldToSearch:
                        fieldName === 'entityOId'
                            ? SubfieldToSearch.SearchDefaultField
                            : SubfieldToSearch.SearchAutoCompleteTerms,
                },
            ];
        }

        result.forEach((element) => {
            element.advancedFieldOptions = GetAdvancedFieldOptions(element.field);
        });

        return result;
    }

    function createSearchRequest(
        searchExecutionType: RequestedSearchExecutionType,
        search: string,
        filter: RequestedIndexSearch['searchFilters'] = []
    ): EntityMasterSearchRequestV4<Pick<SearchEntity, 'entityOId' | 'primaryName' | 'shortName' | 'alternateNames'>> {
        const allFilters: typeof filter = [
            {
                field: 'entityStatusId',
                value: [EntityStatusId.Active],
            },
            {
                field: 'dataOwnerId',
                value: [2],
            },
            {
                field: 'entitySubtypes',
                value: [props.searchType],
            },
            ...filter,
        ];

        const searchFor: RequestedSearchItem[] = [
            {
                queryString: search,
                fuzziness: -1,
                fields:
                    searchExecutionType === RequestedSearchExecutionType.FieldSearch
                        ? GetFieldItem(['entityOId'])
                        : GetFieldItem(['primaryName', 'shortName', 'entityOId', 'alternateNames']),
                searchOperator: SearchOperatorType.Or,
            },
        ];

        const searchRequest: EntityMasterSearchRequestV4<
            Pick<SearchEntity, 'entityOId' | 'shortName' | 'primaryName' | 'alternateNames'>
        > = {
            indexType: IndexType.MdmEntity,
            indexSearchRequest: {
                searchItems: searchFor,
                searchFilters: allFilters,
            },
            resultOptions: {
                numberOfResults: 19,
                fieldsToReturn: ['entityOId', 'primaryName', 'shortName', 'alternateNames'],
                includeMetaData: false,
            },
        };

        return searchRequest;
    }

    useEffect(() => {
        if (
            props.selectedEntityOId !== undefined &&
            props.selectedEntityOId !== null &&
            props.selectedEntityOId !== selectedItem?.entityOId
        ) {
            setIsLoading(true);
            const request = createSearchRequest(
                RequestedSearchExecutionType.FieldSearch,
                props.selectedEntityOId.toString()
            );
            legalEntitySearch.getLegalEntitiesByField(request, 'entityOId').then((result) => {
                const searchResults = GetResultData(result);
                setSelectedItem(searchResults?.[0]);
                setInternalValue(searchResults?.[0]);
                setIsLoading(false);
            });
        } else {
            setSelectedItem(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedEntityOId]);

    function filterEntities(keyword?: string) {
        const abortController = new AbortController();

        if (abortControllers.length) {
            abortControllers[abortControllers.length - 1].abort();
        }

        if (keyword && keyword.length > 0) {
            const filters =
                props.restrictTo === undefined
                    ? []
                    : Object.entries(props.restrictTo)
                          .filter(
                              (
                                  entry
                              ): entry is [keyof NonNullable<ElasticSearchFieldProps['restrictTo']>, number[]] => {
                                  return entry[1] !== undefined;
                              }
                          )
                          .map((entry) => {
                              return { field: entry[0], value: entry[1] };
                          });
            const request = createSearchRequest(RequestedSearchExecutionType.NameSearch, keyword, filters);
            abortControllers.push(abortController);
            legalEntitySearch
                .searchLegalEntity(request, abortController)
                .then((result) => {
                    const searchResults = GetResultData(result);
                    setSearchResults(searchResults);
                })
                .catch(() => {
                    setSearchResults([]);
                    return [];
                });
            return searchResults;
        }
        setSearchResults([]);
        return [];
    }

    useEffect(() => {
        if (isMounting) {
            setIsMounting(false);
        } else {
            props.onEntitySelected(internalValue);
        }
        return () => {
            abortControllers = [];
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalValue]);

    return (
        <FormControl>
            <Grid columnDefinitions='auto * auto' rowDefinitions='*'>
                <TextBlock
                    styleName='fieldLabelStyle'
                    text={props.label}
                    toolTip={props.labelToolTip}
                    margin={`0 0 ${SPACING.XXS}px 0`}
                />
                {isLoading && (
                    <WaitingIndicator
                        id={'spinner'}
                        isVisible
                        isModalToShell={false}
                        fontSize={10}
                        width={40}
                        margin={`-${SPACING.XS}px 0 0 0`}
                    />
                )}
                {props.isReadOnly && props.isPendingChange && (
                    <TextBlock
                        styleName='fieldLabelStyle'
                        fontSize='16px'
                        text='PENDING'
                        foregroundColor={accentColor}
                        textAlignment='right'
                    />
                )}
            </Grid>
            <SelectorField
                itemsSource={searchResults}
                itemTemplate={props.searchType == EntitySubType.ThirdParty ? thirdPartiesTemplate : itemTemplate}
                maxHeight={props.maxHeight}
                selectedValues={selectedItem ? [selectedItem] : []}
                onValueChanged={([newValue]: SearchEntity[]) => {
                    setInternalValue(newValue);
                }}
                searchFilter={filterEntities}
                maxDropDownHeight={275}
                maxDropDownWidth={435}
                showSearchBox
                margin={props.margin}
                validationError={props.validationError}
                isEnabled={props.isEnabled && !isLoading}
                isReadOnly={props.isReadOnly}
                minWidth={'25%'}
                canResetValue
                isRequired={props.isRequired ?? false}
            />
        </FormControl>
    );
}

ElasticSearchField.defaultProps = {
    isEnabled: true,
    isReadOnly: false,
    canClearSelection: true,
};
